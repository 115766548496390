/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import logo from "../../assets/img/brand/logo.png";
import Trusted from "../../assets/img/svgs/collateral-icon.svg";
import HeroBanner from "../../assets/img/transparent-hero.png";
import ReviewAvatar from "../../assets/img/review-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidator from "../../hooks/useValidator";
import useAuth from "../../hooks/useAuth";
import { getUserProfile } from "../../data/api";
import NeedHelp from "../components/NeedHelp";

const SignIn = () => {
  const navigate = useNavigate();
  const [formDetails, setFormDetail] = useState({
    email: "",
    password: ""
  });
  const { setAuth, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [firstFormValidator, showFirstValidationMessage] = useValidator({});

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
  };

  const { username, password } = formDetails;

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.API_URL}/oauth/token`,
          formDetails
        );
        if (response.status === 200) {
          setIsLoading(false);
          const accessToken = response?.data?.data?.access_token;
          const refreshToken = response?.data?.data?.refresh_token;
          setAuth({ accessToken, refreshToken });

          const profileResponse = await getUserProfile(accessToken);
          if (profileResponse.status === 200) {
            // const user = profileResponse?.data;
            setUser(profileResponse?.data?.data);
            localStorage.setItem(
              "auth",
              JSON.stringify({ accessToken, refreshToken })
            );
            navigate("/apply");
          }
        }
      } catch (err) {
        setIsLoading(false);

        /*
          If a user sign ups the first time without confirming his phone number, it wll throw a phone number
          error, so send a new request to the OTP endpoint and redirect them to the OTP page
        */
        if (err?.response?.data?.details?.phoneNumber) {
          toast.error(
            `Generate OTP gone wrong. ${err.response?.data?.message}`
          );
          toast.loading(
            "Generating a new otp for you"
          );
          axios
            .post(
              `${process.env.API_URL}/customers/${err?.response?.data?.details?.email}/generate-otp/${err?.response?.data?.details?.phoneNumber}`
            )
          // eslint-disable-next-line no-unused-vars
            .then(function (response) {
              toast.success("OTP Generated, Please check your phone");
              navigate("/signin/resend-otp", { state: { details: err?.response?.data?.details, password } });
            })
            .catch(function (error) {
              toast.error(
                `Couldn't generate an OTP ${error.response?.data?.message}`
              );
            });
        }
        toast.error(`Couldnt signin. ${err.response?.data?.message}`);
      }
    } else {
      showFirstValidationMessage(true);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <>
      <div className="new-signup-wrapper">
        <img src={HeroBanner} alt="" className="signup-bg-img" />
        <div className="new-signup-container">
          <div className="marketing-navbar">
            <Link to="/">
              <img src={logo} alt="monthly logo" />
            </Link>
          </div>
          <div className="new-signup-section">
            <div className="signup-left">
              <h2>
                <span>Welcome!</span>👋🏾Securely login to your Monthly Account
              </h2>
              <div className="sub-signup-left">
                <div className="trusted-button">
                  <img src={Trusted} alt="Trusted icon" />
                  <h6>
                    Trusted by renters like <span>you</span>
                  </h6>
                </div>
                <>
                  <div className="signup-review">
                    “ Thanks to the guys at Monthly I was able to easily pay for
                    the rent for my new place easily without breaking a sweat
                    and focus my finances on the several other bits that come
                    with getting a new place. The customer support team was
                    really helpful, quick to respond and always happy to help “
                  </div>
                  <div className="review-avatar">
                    <img src={ReviewAvatar} alt="review-avatar" />
                    <h4>Amos, Software Engineer</h4>
                  </div>
                </>
              </div>
            </div>
            <div className="signup-right">
              <h1 className="mobile-signup-header">
                <span>Welcome!</span>👋🏾Securely login to your Monthly Account
              </h1>

              <form className="signup-form">
                <div className="signup-formgroup">
                  <label>USERNAME OR EMAIL ADDRESS</label>
                  <input
                    type="text"
                    name="username"
                    onChange={handleFormChange}
                    value={username}
                  />
                  {firstFormValidator.message(
                    "username",
                    formDetails.username,
                    "required"
                  )}
                </div>
                <div className="signup-formgroup">
                  <label>PASSWORD</label>
                  <div>
                    <input
                      type={passwordType}
                      name="password"
                      onChange={handleFormChange}
                      value={password}
                    />
                    {passwordType === "password" ? (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={togglePassword}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faEye} onClick={togglePassword} />
                    )}
                  </div>
                  {firstFormValidator.message(
                    "password",
                    formDetails.password,
                    "required"
                  )}
                </div>
                <button className="signup-button" onClick={handleSignIn}>
                  {isLoading ? " ... " : "PROCEED"}
                </button>
                <div className="signup-review text-center">
                  Don't have an account ?{" "}
                  <Link to="/register/signup" className="signup-link">
                    Sign Up
                  </Link>
                </div>
                <div className="text-center">
                  <Link to="/forgot-password" className="signup-link">
                    Forgot Username and Password
                  </Link>
                </div>
              </form>
              <NeedHelp />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
};

export default SignIn;
