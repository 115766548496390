/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import logo from "../../assets/img/brand/logo.png";
import Trusted from "../../assets/img/svgs/collateral-icon.svg";
import HeroBanner from "../../assets/img/transparent-hero.png";
import ReviewAvatar from "../../assets/img/review-avatar.png";
import ReviewAvatar2 from "../../assets/img/review-avatar2.png";
import ReviewAvatar3 from "../../assets/img/review-avatar3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPhone,
  faEyeSlash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidator from "../../hooks/useValidator";
import useAuth from "../../hooks/useAuth";
import { getUserProfile } from "../../data/api";

export default function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  // const searchParams = useSearchParams();
  const storedReferral = localStorage.getItem("refer");
  const storedEmail = localStorage.getItem("email");
  const [disabledReferral, setDisabledReferral] = useState(false);
  const [formDetails, setFormDetail] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: storedEmail || "",
    password: "",
    channel: "web",
    referred_by: storedReferral || ""
  });
  const [formState, setFormState] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingValidate, setIsLoadValidate] = useState(false);
  const [isCallInitiated, setCallInitiated] = useState(false);
  const [isLoadingRegenerate, setIsLoadingRegenerate] = useState(false);
  const [isLoadingReferral, setIsLoadingReferral] = useState(false);
  const [otpInput, setOTPInput] = useState("");
  const [referralName, setReferralName] = useState("");
  const [referralStatus, setReferralStatus] = useState(true);
  const [editPhone, setEditPhone] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { setAuth, setUser } = useAuth();

  const customValidator = {
    password: {
      message:
        "Password should be at least 8 characters, contain a lowercase, uppercase and a number",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
        );
      },
      required: true
    }
  };
  const [firstFormValidator, showFirstValidationMessage] = useValidator(
    {},
    customValidator
  );

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
  };

  const verifyReferrals = (code) => {
    setIsLoadingReferral(true);
    setReferralStatus(true);
    axios
      .post(`${process.env.API_URL}/referrals/verify`, { referral_code: code })
      .then(function (response) {
        setIsLoadingReferral(false);
        if (response?.data) {
          setReferralName(response?.data?.data.referrer_name);
          setReferralStatus(response?.data?.data?.valid);
        }
        if (response?.data?.data.referrer_name) {
          setFormDetail({ ...formDetails, referred_by: storedReferral || code });
        }
      })
      .catch(function (error) {
        setIsLoadingReferral(false);
        setReferralStatus(true);
        toast.error(
          `Couldnt validate Referral Code. ${error.response?.data?.message}`
        );
      });
  };

  const toggleEditPhone = () => {
    setEditPhone(!editPhone);
  };

  useEffect(() => {
    if (storedReferral) {
      verifyReferrals(storedReferral);
      setDisabledReferral(true);
    }
  }, []);

  const handleVerifyReferral = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
    if (value?.length > 4) {
      verifyReferrals(e.target.value);
    } else {
      setReferralStatus(true);
    }
  };

  useEffect(() => {
    // Parse the query parameters
    const params = new URLSearchParams(location.search);

    // Create an object to store the query parameters
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    setFormDetail({ ...formDetails, first_name: paramsObject?.fname,
      last_name: paramsObject?.lname, phone_number: paramsObject?.num, email: paramsObject?.email, referred_by: paramsObject?.ref });

    // --| Verify the referral code
    if (paramsObject.ref) {
      axios
        .post(`${process.env.API_URL}/referrals/verify`, { referral_code: paramsObject.ref })
        .then(function (response) {
          if (response?.data) {
            setReferralName(response?.data?.data.referrer_name);
            setReferralStatus(response?.data?.data?.valid);
          }
        })
        .catch(function () {
        });
    }
  }, [location.search]);

  const handleOTPChange = (e) => {
    setOTPInput(e.target.value);
  };

  const {
    first_name,
    last_name,
    phone_number,
    email,
    password,
    referred_by
  } = formDetails;

  const generateOTP = async (e) => {
    e.preventDefault();
    setIsLoadingRegenerate(true);
    axios
      .post(
        `${process.env.API_URL}/customers/${email}/generate-otp/${phone_number}`
      )
      // eslint-disable-next-line no-unused-vars
      .then(function (response) {
        setIsLoadingRegenerate(false);
        setEditPhone(false);
        toast.success("OTP Generated");
      })
      .catch(function (error) {
        setIsLoadingRegenerate(false);
        toast.error(
          `Generate OTP gone wrong. ${error.response?.data?.message}`
        );
      });
  };

  const dictateOTP = async () => {
    setCallInitiated(true);
    axios
      .post(`${process.env.API_URL}/customers/${email}/dictate-otp`)
      // eslint-disable-next-line no-unused-vars
      .then(function (response) {
        setCallInitiated(false);
        toast.success("Call Initiated");
      })
      .catch(function (error) {
        setCallInitiated(false);
        toast.error(
          `Generate OTP gone wrong. ${error.response?.data?.message}`
        );
      });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      setIsLoading(true);
      axios
        .post(`${process.env.API_URL}/customers`, formDetails)
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          setIsLoading(false);
          setFormState(2);
        })
        .catch(function (error) {
          setIsLoading(false);
          toast.error(`Couldnt signup. ${error.response?.data?.message}`);
        });
    } else {
      showFirstValidationMessage(true);
    }
  };

  const validateOTP = async (e) => {
    e.preventDefault();
    const body = { phone_number, otp: otpInput };
    setIsLoadValidate(true);
    await axios
      .post(`${process.env.API_URL}/customers/verify-otp`, body)
      .then(function (response) {
        toast.success("Successfully Verified");
        if (response?.data) {
          toast.loading("Logging you in");
          // clearState();

          // Login into the app to get the token

          axios
            .post(`${process.env.API_URL}/oauth/token`, {
              username: email,
              password
            })
            .then((response) => {
              if (response.status === 200) {
                setIsLoadValidate(false);
                setIsLoading(false);
                const accessToken = response?.data?.data?.access_token;
                const refreshToken = response?.data?.data?.refresh_token;
                setAuth({ accessToken, refreshToken });

                getUserProfile(accessToken).then((profileResponse) => {
                  if (profileResponse?.status === 200) {
                    setUser(profileResponse?.data?.data);
                    localStorage.setItem(
                      "auth",
                      JSON.stringify({ accessToken, refreshToken })
                    );
                    navigate("/apply");
                  }
                });
              }
            });
        }
      })
      .catch(function (error) {
        setIsLoadValidate(false);
        toast.error(`Couldnt validate OTP. ${error.response?.data?.message}`);
      });
  };

  const MEDIUM = [
    { name: "Select Medium", value: "" },
    { name: "Social media (Instagram, Facebook etc)", value: "social_media" },
    { name: "Google", value: "google" },
    { name: "Someone you know", value: "someone_you_know" },
    {
      name: "Property website (jiji, property centre etc)",
      value: "property_site"
    },
    { name: "Blog or website", value: "blog_or_website" },
    { name: "App store search", value: "app_store" },
    { name: "Other", value: "other" }
  ];
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <>
      <div className="new-signup-wrapper">
        <img src={HeroBanner} alt="" className="signup-bg-img" />
        <div className="new-signup-container">
          <div className="marketing-navbar">
            <img src={logo} alt="monthly logo" />
          </div>
          <div className="new-signup-section">
            <div className="signup-left">
              <h2>
                Find your dream <span>home</span> and pay rent{" "}
                <span>monthly</span>
              </h2>
              <div className="sub-signup-left">
                <div className="trusted-button">
                  <img src={Trusted} alt="Trusted icon" />
                  <h6>
                    Trusted by renters like <span>you</span>
                  </h6>
                </div>
                {formState === 1 ? (
                  <>
                    <div className="signup-review">
                      “ Thanks to the guys at Monthly I was able to easily pay
                      for the rent for my new place easily without breaking a
                      sweat and focus my finances on the several other bits that
                      come with getting a new place. The customer support team
                      was really helpful, quick to respond and always happy to
                      help “
                    </div>
                    <div className="review-avatar">
                      <img src={ReviewAvatar} alt="review-avatar" />
                      <h4>Amos, Software Engineer</h4>
                    </div>
                  </>
                ) : formState === 2 ? (
                  <>
                    <div className="signup-review">
                      “ Monthly was very helpful when our business needed to
                      expand, kudos to the team at monthly “
                    </div>
                    <div className="review-avatar">
                      <img src={ReviewAvatar2} alt="review-avatar" />
                      <h4>Corporate Ewa</h4>
                    </div>
                  </>
                ) : formState === 3 ? (
                  <>
                    <div className="signup-review">
                      “ Monthly is a life-saver. I stumbled on them online and
                      decided to try them out. I was first all amazed by their
                      quick response. To cap it all they completely funded my
                      new apartment which I currently live in. I am still amazed
                      “
                    </div>
                    <div className="review-avatar">
                      <img src={ReviewAvatar3} alt="review-avatar" />
                      <h4>Anthony, Fashion Designer</h4>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="signup-right">
              <h1 className="mobile-signup-header">
                Find your dream
                <span> home</span> and pay rent <span>monthly</span>
              </h1>
              {formState === 1 ? (
                <form className="signup-form">
                  <div className="signup-formgroup">
                    <label>FIRST NAME</label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={handleFormChange}
                      value={first_name}
                    />
                    {firstFormValidator.message(
                      "first_name",
                      formDetails.first_name,
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>LAST NAME</label>
                    <input
                      type="text"
                      name="last_name"
                      onChange={handleFormChange}
                      value={last_name}
                    />
                    {firstFormValidator.message(
                      "last_name",
                      formDetails.last_name,
                      "required"
                    )}
                  </div>
                  <div className="signup-flexformgroup">
                    <div className="small-formgroup">
                      <label>EMAIL ADDRESS</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleFormChange}
                        value={email}
                      />
                      {firstFormValidator.message(
                        "email",
                        formDetails.email,
                        "required|email"
                      )}
                    </div>
                    <div className="small-formgroup">
                      <label>PHONE NUMBER</label>
                      <input
                        type="tel"
                        name="phone_number"
                        maxLength={12}
                        onChange={handleFormChange}
                        value={phone_number}
                      />
                      {firstFormValidator.message(
                        "phone_number",
                        formDetails.phone_number,
                        "required|max:12"
                      )}
                    </div>
                  </div>
                  <div className="signup-formgroup">
                    <label>PASSWORD</label>
                    <div>
                      <input
                        type={passwordType}
                        name="password"
                        onChange={handleFormChange}
                        value={password}
                      />
                      {passwordType === "password" ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={togglePassword}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                    {firstFormValidator.message(
                      "password",
                      formDetails.password,
                      "required|password"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>REFERRAL</label>
                    <input
                      type="text"
                      name="referred_by"
                      onChange={handleVerifyReferral}
                      value={referred_by}
                      disabled={disabledReferral}
                    />
                    <p className="success-tag">
                      {isLoadingReferral ? "..." : null}
                    </p>
                    {!referralStatus ? (
                      <p className="error-tag"> Invalid Referral Code</p>
                    ) : null}
                    {referralName ? (
                      <p className="success-tag">
                        <span>Referred By : </span>
                        {referralName}
                      </p>
                    ) : null}
                  </div>
                  <div className="signup-formgroup">
                    <label>HOW DID YOU FIRST HEAR ABOUT US</label>
                    <select onChange={handleFormChange} name="medium">
                      {MEDIUM.map((medium, i) => {
                        return (
                          <option value={medium.value} key={i}>
                            {medium.name}
                          </option>
                        );
                      })}
                    </select>
                    {firstFormValidator.message(
                      "medium",
                      formDetails.medium,
                      "required"
                    )}
                  </div>
                  <button className="signup-button" onClick={handleSignUp}>
                    {isLoading ? "..." : "CREATE ACCOUNT"}
                  </button>
                </form>
              ) : formState === 2 ? (
                <form className="signup-form">
                  <h5 className="new-signup-title">Enter code sent to</h5>
                  <div className="new-signup-subtitle">
                    {" "}
                    {!editPhone ? (
                      phone_number
                    ) : (
                      <div className="signup-formgroup">
                        <input
                          type="tel"
                          name="phone_number"
                          value={phone_number}
                          onChange={handleFormChange}
                          maxLength={12}
                        />
                        <button
                          className="edit-phone-btn"
                          onClick={generateOTP}
                        >
                          {isLoadingRegenerate ? "..." : "SAVE"}
                        </button>
                      </div>
                    )}{" "}
                    {!editPhone ? (
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        onClick={toggleEditPhone}
                      />
                    ) : null}
                  </div>

                  {!editPhone ? (
                    <>
                      <div className="signup-formgroup">
                        <label>CODE</label>
                        <input
                          type="text"
                          onChange={handleOTPChange}
                          name="otpInput"
                          value={otpInput}
                        />
                        {firstFormValidator.message(
                          "otp",
                          otpInput,
                          "required"
                        )}
                      </div>
                      <button className="signup-button" onClick={validateOTP}>
                        {isLoadingValidate ? "..." : "CONTINUE"}
                      </button>
                      <div className="otp-subfooter">
                        {isLoadingRegenerate ? (
                          <div>Generating . . .</div>
                        ) : (
                          <div onClick={generateOTP}>Resend Code</div>
                        )}

                        {isCallInitiated ? (
                          <div>Processing . . . </div>
                        ) : (
                          <div onClick={dictateOTP}>
                            <FontAwesomeIcon icon={faPhone} /> Call me instead
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
}
