// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from "react";
import {
  WeValueYourPrivacy,
  InformationWeCollect,
  NonPersonalInformation,
  Cookies,
  UseOfPersonalInformation,
  AutomatedProcessing,
  InformationWeShare,
  HowWeProtectYourInformation,
  WhereWeStoreYourInformation,
  HowLongWeStoreYourInformation,
  YourRights,
  MaintainAccurateInformation,
  ThirdPartySitesAndServices,
  SocialMediaPlatforms,
  PromotionalMessages,
  PrivacyPolicyChanges
} from "./components/PrivacyText";

export default () => {
  const Headings = ({ headings, activeId }) => (
    <ul>
      {headings.map((heading) => (
        <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
          <a
            href={`#${heading.id}`}
            onClick={(e) => {
              e.preventDefault();
              document.querySelector(`#${heading.id}`).scrollIntoView({
                behavior: "smooth"
              });
            }}
          >
            {heading.title}
          </a>
        </li>
      ))}
    </ul>
  );

  const getHeadings = (headingElements) => {
    const headings = [];
    headingElements.forEach((heading) => {
      const { innerText: title, id } = heading;
      headings.push({ id, title });
    });

    return headings;
  };

  // Dynamically generates the table of contents list, using any H2s it can find in the main text
  const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = useState([]);

    useEffect(() => {
      const headingElements = Array.from(
        document.querySelectorAll(".toc-content h2")
      );
      // Created a list of headings, with H3s
      const newHeadings = getHeadings(headingElements);
      setNestedHeadings(newHeadings);
    }, []);

    return { nestedHeadings };
  };

  const useIntersectionObserver = (setActiveId) => {
    const headingElementsRef = useRef({});
    useEffect(() => {
      const headingElements = Array.from(document.querySelectorAll("h2"));

      const callback = (headings) => {
        headingElementsRef.current = headings.reduce((map, headingElement) => {
          map[headingElement.target.id] = headingElement;

          return map;
        }, headingElementsRef.current);

        // Get all headings that are currently visible on the page
        const visibleHeadings = [];
        Object.keys(headingElementsRef.current).forEach((key) => {
          const headingElement = headingElementsRef.current[key];
          if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
        });

        const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

        // If there is only one visible heading, this is our "active" heading
        if (visibleHeadings.length === 1) {
          setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading, choose the one that is closest to the top of the page
        } else if (visibleHeadings.length > 1) {
          const sortedVisibleHeadings = visibleHeadings.sort(
            (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
          );

          setActiveId(sortedVisibleHeadings[0].target.id);
        }
      };

      const observer = new IntersectionObserver(callback, { rootMargin: "0px 0px -40% 0px" });

      headingElements.forEach((element) => observer.observe(element));

      return () => observer.disconnect();
    }, [setActiveId]);
  };

  // Renders the table of contents.
  const TableOfContents = () => {
    const [activeId, setActiveId] = useState();
    const { nestedHeadings } = useHeadingsData();
    useIntersectionObserver(setActiveId);

    return (
      <nav aria-label="Table of contents" className="toc-nav align-self-start">
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
    );
  };

  return (
    <>
      <div className="container privacy-container d-flex justify-content-center">
        <TableOfContents />
        <div className="toc-content">
          <h1>Privacy Policy</h1>
          <div className="mb-4"><small>Version date: May 2022</small></div>

          <h2 id="value-privacy">We value your privacy</h2>
          <WeValueYourPrivacy />

          <h2 id="information-collect">Information we collect and use</h2>
          <InformationWeCollect />

          <h2 id="non-personal">Non-personal information collected by us</h2>
          <NonPersonalInformation />

          <h2 id="cookies">Cookies</h2>
          <Cookies />

          <h2 id="personal-info">Use of personal information</h2>
          <UseOfPersonalInformation />

          <h2 id="automated">Automated processing</h2>
          <AutomatedProcessing />

          <h2 id="info-share">Information we share</h2>
          <InformationWeShare />

          <h2 id="info-protect">How we protect your information</h2>
          <HowWeProtectYourInformation />

          <h2 id="info-store">Where we store your information</h2>
          <WhereWeStoreYourInformation />

          <h2 id="how-long">How long we store your information</h2>
          <HowLongWeStoreYourInformation />

          <h2 id="rights">Your rights</h2>
          <YourRights />

          <h2 id="maintain">Maintain accurate information</h2>
          <MaintainAccurateInformation />

          <h2 id="third-party">Third party sites and services</h2>
          <ThirdPartySitesAndServices />

          <h2 id="social-media">Social medium platforms</h2>
          <SocialMediaPlatforms />

          <h2 id="promo">Promotional messages</h2>
          <PromotionalMessages />

          <h2 id="changes">Privacy policy changes</h2>
          <PrivacyPolicyChanges />
        </div>
      </div>

    </>
  );
};
