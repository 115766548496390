// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "./components/home/Banner";
import HowitWorks from "./components/home/HowitWorks";
import Benefits from "./components/home/Benefits";
import Comparison from "./components/home/Comparison";
import SecondBanner from "./components/home/SecondBanner";

export default () => {
  const location = useLocation();

  // handles scroll spy; scrolls to the element with id in the state param
  useEffect(() => {
    if (location.state && location.state.section) {
      document.querySelector(`#${location.state.section}`).scrollIntoView({
        behavior: "smooth"
      });
    }
    location.state = null;
  }, [location]);

  return (
    <>
      <Banner />
      <HowitWorks />
      <Benefits />
      <Comparison />
      <SecondBanner />
    </>
  );
};
