// eslint-disable-next-line no-unused-vars
import React from "react";
import { authUrl, logoutUrl } from "../data/api";

export const redirectToLogin = () => {
  window.location.assign(authUrl);
};

export const redirectToLogout = async () => {
  window.location.assign(`${logoutUrl}`);
};
