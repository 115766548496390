// eslint-disable-next-line no-unused-vars
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [staticConfig, setStaticConfig] = useState({});

  const STATIC_CONFIG = "https://staging-monthlyng-configs.s3.eu-central-1.amazonaws.com/static/config.json";

  useEffect(() => {
    axios.get(STATIC_CONFIG)
      .then((res) => setStaticConfig(res?.data))
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, []);

  return (
    <AppContext.Provider value={{ staticConfig, setStaticConfig }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
