// eslint-disable-next-line no-unused-vars
import React from "react";
import Rent from "../../../assets/img/svgs/rent-icon.svg";
import Time from "../../../assets/img/svgs/24hr-icon.svg";
import Everyone from "../../../assets/img/svgs/everyone-icon.svg";
import Collateral from "../../../assets/img/svgs/collateral-icon.svg";

const WhyRentals = () => {
  return (
    <div className="how-wrapper">
      <div className="page-container">
        <div className="how-title">
          Why People love Monthly
        </div>
        <div className="card-flex">
          <div className="why-card card-one">
            <img src={Rent} alt="why-icon" className="why-icon" />
            <h5>Rent any property anywhere you want</h5>
            <p>Lekki, Yaba or Ikorodu. Any property anywhere in Lagos and pay monthly</p>
          </div>
          <div className="why-card card-two">
            <img src={Collateral} alt="why-icon" className="why-icon" />
            <h5>No collateral,No co-signer,No harassment.</h5>
            <p>Enjoy privacy and freedom when paying rent with Monthly</p>
          </div>
          <div className="why-card card-three">
            <img src={Time} alt="why-icon" className="why-icon" />
            <h5>Your rent paid in 24 hours</h5>
            <p>Time is of the essence. Application, approval and payment in 24 hours</p>
          </div>
          <div className="why-card card-four">
            <img src={Everyone} alt="why-icon" className="why-icon" />
            <h5>Everyone can apply</h5>
            <p>Freelancer or salary earner. Everyone is welcome to pay with Monthly</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyRentals;
