// eslint-disable-next-line no-unused-vars
import React from "react";
export default () => {
  return (
    <>
      <section className="faq-header page-padding">
        <div className="container text-center">
          <h2 className="text-bold">How Monthly Works</h2>
          <p className="mt-4">Want to get a rent loan quickly? Monthly is very easy to use and this is how it works.</p>
        </div>
      </section>
    </>
  );
};
