// eslint-disable-next-line no-unused-vars
import React from "react";
import { Image } from "react-bootstrap";
import Steps from "./Steps";
import M20 from "../../../assets/img/m20.png";

export default () => {
  return (
    <>
      <section className="how-to">
        <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div className="how-to-section how-to-description">
            <h3 className="text-bold">Get a Rent Loan in just</h3>
            <h3 className="text-success text-bold mb-5">24 hours</h3>
            <Steps />
          </div>
          <div className="how-to-section how-to-image-box">
            <Image src={M20} className="how-to-image" />
          </div>
        </div>
      </section>
    </>
  );
};
