// eslint-disable-next-line no-unused-vars
import React from "react";
import axios from "axios";

// const TOKEN_ENDPOINT = "/oauth/token";
const REFRESH_TOKEN_ENDPOINT = "/oauth/token/refresh";
const PROFILE_ENDPOINT = "/customers";

export const axiosInstance = axios.create({
  baseURL: `${process.env.API_URL}`
});

export const axiosProtected = axios.create({
  baseURL: `${process.env.API_URL}`,
  headers: {
    "Content-Type": "application/json"
  }
});

export const authUrl = `${process.env.BASE_URL}/apply`;
export const logoutUrl = `${process.env.BASE_URL}/signin`;

export async function getNewToken(refreshToken) {
  // eslint-disable-next-line no-return-await
  return await axiosInstance.post(
    REFRESH_TOKEN_ENDPOINT, {
      refresh_token: refreshToken,
      grant_type: "refresh_token"
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    });
}

export async function getUserProfile(token) {
  // eslint-disable-next-line no-return-await
  return await axiosInstance.get(
    PROFILE_ENDPOINT,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${token}`
      }
    });
}
