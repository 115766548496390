// eslint-disable-next-line no-unused-vars
import React from "react";
import logo from "../../assets/img/brand/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

export default function ApplyNavbar() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  return (
    <div>
      {" "}
      <div className="marketing-navbar">
        <Link to="/">
          <img src={logo} alt="monthly logo" />
        </Link>
        <div className="apply-navbar">
          <Link to="/apply">
            <FontAwesomeIcon icon={faList} />
            Apply
          </Link>
        </div>
        <button className="apply-signout" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOut} />
          <span className="ml-2">Sign Out</span>
        </button>
      </div>
    </div>
  );
}
