// eslint-disable-next-line no-unused-vars
import React from "react";
const WeValueYourPrivacy = () => {
  return (
    <>
      <p>
        We treat your personal information as private and confidential. We are dedicated to protecting your privacy
        and providing you with the highest level of security at any point of interaction with us. This Privacy Policy
         describes what personal information we collect, what we do with it and how we protect it.
      </p>
      <p>

        This policy (together with our Terms and Conditions) sets out the basis on which any personal data we collect
        from you, or that you provide to us, will be processed by us. Please read the following carefully to understand
        our views and practices regarding your personal data and how we will treat it. By continuing to visit our website
        <a href="https://monthly.ng"> www.monthly.ng</a>, you accept and consent to the practices described in this policy.
      </p>
    </>

  );
};
const InformationWeCollect = () => {
  return (
    <>
      <p>
        We collect information about you from a variety of sources, such as: website visits, applications,
        identification documents, curriculum vitae, personal financial statements, interactions with relationship
        managers, and other third parties (credit bureaus, payment gateways, other financial institutions) and
        other written or electronic communication reflecting information such as your name, address, passport
        details, identification numbers, biometric information, telephone number, occupation, assets, income and any
        other information we deem necessary.
      </p>
      <p>
        We may also use your transactional account history including your account
        balance, payment records, and debit/credit card usage. We may also use information received from third parties
        such as family, solicitors, friends or employers, website/ social media pages made public by you,
        government agencies, regulators, supervisory or credit agencies. We may also collect other information
        such as video footages of you whenever you step into any of our offices, telephone conversations when you
        call any of our contact centre lines, geographic information of any of our offices or partners when you visit.
      </p>
    </>
  );
};
const NonPersonalInformation = () => {
  return (
    <p>
        In order to achieve our goal of providing you with the best service, we sometimes collect certain information
        during your visits to perform certain tasks such as grant you access to some parts of our web site or conduct
        research on your behaviour on our site in order to improve our services. We will not disclose your information
        to any person outside our organization except as described in this Privacy Policy.
    </p>
  );
};
const Cookies = () => {
  return (
    <>
      <p>
        Cookies are small text files stored on your computer or mobile devices whenever you visit a website.
        Cookies have many uses, such as memorizing your preferences to tailor your experiences while on our site– or
        to help us analyse our website traffic.
      </p>
      <p>
        We use cookies to improve your experience while on this site.
        We would like to let you know a few things about our cookies:
      </p>
      <ul>
        <li>Some cookies are essential to access certain areas of this site;</li>
        <li>We use analytics cookies to help us understand how you use our site to discover what content is
              most useful to you
        </li>
      </ul>
    </>
  );
};
const UseOfPersonalInformation = () => {
  return (
    <>
      <p>
        Our collection and use of personal data is guided by certain principles.
        These principles state that personal data should:
      </p>
      <ul>
        <li>be processed fairly, lawfully and in a transparent manner.</li>
        <li>
            be obtained for a specified and lawful purpose and shall not be processed in any
            manner incompatible with such purposes.
        </li>
        <li>
            be adequate, relevant and limited to what is necessary to fulfil the purpose of processing.
        </li>
        <li>
            be accurate and where necessary, up-to-date. In the event data is inaccurate, steps should be taken
            to rectify or erase such data.
        </li>
        <li>
            not be kept for longer than necessary for the purpose of processing.
        </li>
        <li>
            be processed in accordance with the data subject’s rights
        </li>
        <li>
            be kept safe from unauthorised processing, and accidental loss, damage or destruction using
            adequate technical and organizational measures.
        </li>
      </ul>
      <p>
        Any personal information provided by you will be used with your consent, or when we have another
        lawful reason. These include:
      </p>
      <ul>
        <li>Cases where processing of personal data is required for the fulfilment of a contractual obligation.</li>
        <li>Cases where processing of personal data is required for compliance with legal and/or
            regulatory requirements.
        </li>
        <li>Cases where processing is required to protect your vital interest or that of any other natural person.</li>
        <li>Cases where processing is required for an activity to be carried in significant public interest.</li>
        <li>Cases where processing is required for our legitimate interests or a third party insofar as this
             does not conflict with the requirements for the protection of your personal data.
        </li>
      </ul>

      <p>Your personal information is used in:</p>
      <ul>
        <li>Updating and enhancing our records</li>
        <li>Executing your instructions.</li>
        <li>Establishing your identity and assessing applications for our products and services</li>
        <li>Pricing and designing our products and services</li>
        <li>Administering our products and services</li>
        <li>Managing our relationship with you</li>
        <li>Managing our risks</li>
        <li>Identifying and investigating illegal activity (i.e.), such as fraud</li>
        <li>Contacting you, for example in instances where we suspect fraud on your account or when the need arises
             to tell you about recent occurrences or some event(s) of significance.
        </li>
        <li>Conducting and improving our businesses and improving your experience with us</li>
        <li>Reviewing credit or loan eligibility.</li>
        <li>Preventing money laundering or terrorism financing activities.</li>
        <li>Complying with our legal obligations and assisting government and law enforcement agencies
            or regulators/supervisors
        </li>
        <li>Identifying and informing you about other products or services that we think may be of interest to you.</li>
        <li>Processing your job application if you apply for a job with us.</li>
      </ul>

      <p>
         We may also collect, use and exchange your information in other ways permitted by law.
      </p>
    </>
  );
};

const AutomatedProcessing = () => {
  return (
    <>
      <p>
          We sometimes use automated systems and software to help us reach decisions about you, for example,
          to make credit decisions, to carry out security, fraud and money laundering checks, or to process your
          data when you apply for some of our products and services.
      </p>
      <p>
          This type of processing is carried out under lawful basis and you can contact us to request that
          automated processing be reviewed by a human being if you detect any inaccuracies in your personal data.
      </p>
    </>
  );
};

const InformationWeShare = () => {
  return (
    <>
      <p>
        We may share the information about you and your dealings with us, to the extent permitted by law with third
        parties.
      </p>
      <p>
        We may also disclose information about you if we determine that disclosure is reasonably necessary
        to enforce our terms and conditions or protect our operations or users. Additionally, in the event of a
        reorganization, merger, or sale we may transfer any and all personal information we collect
        to the relevant third party.
      </p>
      <p>
        Information about our customers and their usage of our website is not shared with third parties for
        their marketing purposes. We do not disclose any information about any user’s usage of our web site
        except in specific cases, and we do not share information with any unaffiliated third parties for
        marketing purposes unless you expressly give us permission to do so.
      </p>
    </>
  );
};
const HowWeProtectYourInformation = () => {
  return (
    <>
      <p>
        We take appropriate technical and organizational measures to prevent loss, unauthorized access,
        misuse, modification or disclosure of information under our control. This may include the use of
        encryption, access controls and other forms of security to ensure that your data is protected. We
        require all parties including our staff and third-parties processing data on our behalf to comply
        with relevant policies and guidelines to ensure that information is protected in use, when stored
        and during transmission.
      </p>
      <p>Your personal information with us remains secure because:</p>
      <ul>
        <li>We use strict security measures and technologies to prevent fraud and intrusion.</li>
        <li>Our Security controls and processes are regularly updated to meet and exceed industry standards.</li>
        <li>Our employees are trained to respect the confidentiality of any personal information held by us</li>
      </ul>
      <p>
        Where we have provided you (or where you have chosen) a password which grants you access to specific
        areas on our site, you are responsible for keeping this password confidential. We request that you do
        not to share your password or other authentication details (e.g. token generated codes) with anyone.
      </p>
    </>
  );
};
const WhereWeStoreYourInformation = () => {
  return (
    <>
      <p>
        All Personal Information you provide to us is stored on our secure servers as well as secure physical
        locations and cloud infrastructure (where applicable) for the purposes of providing seamless services
        to you, including but not limited to ensuring business continuity, the data that we collect from you
        may be transferred to or stored in cloud locations at globally accepted vendors' data center.
        Whenever your information is transferred to other locations, we will take all necessary steps to ensure
        that your data is handled securely and in accordance with this privacy policy.
      </p>
    </>
  );
};
const HowLongWeStoreYourInformation = () => {
  return (
    <>
      <p>
        We retain your data for as long as is necessary for the purpose(s) that it was collected.
        Storage of your data is also determined by legal, regulatory, administrative or operational
        requirements. We only retain information that allows us comply with legal and regulatory
        requests for certain data, meet business and audit requirements, respond to complaints and queries,
        or address disputes or claims that may arise.
      </p>
      <p>
        Data which is not retained is securely destroyed when it is identified that is no longer needed
        for the purposes for which it was collected.
      </p>
    </>
  );
};
const YourRights = () => {
  return (
    <>
      <p>
        You have certain rights available to you, these include
      </p>
      <ul>
        <li>
          The right to access your personal information held by us. Your right of access can be exercised by
          sending an email to <a href="mailto:support@monthly.ng">support@monthly.ng</a>
        </li>
        <li>
            The right to rectify inaccurate or incomplete information.
        </li>
        <li>withdraw consent for processing in cases where consent has previously been given.</li>
        <li>
          restrict or object to processing of your personal data. We might continue to process your data if
          there are valid legal or operational reasons
        </li>
      </ul>
      <p>
        You also have the right to:
      </p>
      <ul>
        <li>
          Request that your personal data be made available to you in a common electronic format and/or request
          that such data be sent to a third party.
        </li>
        <li>
          Request that your information be erased. We might continue to retain such data if there are valid legal,
          regulatory or operational reasons.
        </li>
      </ul>
    </>
  );
};
const MaintainAccurateInformation = () => {
  return (
    <>
      <p>
        Keeping your account information accurate and up to date is very important. You have access to your
        account information, which includes your contact information, account balances, transactions and
        similar information through various means, such as account statements.
      </p>
      <p>
        If you discover any inaccuracies in your personal information, please promptly notify us, via our
        branch network or Contact Centre, and provide the required documentary evidence, to enable us to
        implement the necessary updates or changes.
      </p>
    </>
  );
};
const ThirdPartySitesAndServices = () => {
  return (
    <>
      <p>
        Our websites, products, applications, and services may contain links to third-party websites,
        products and services. Our products and services may also use or offer products or services from
        third parties. Information collected by third parties, which may include such things as location
        data or contact details is governed by their privacy practices and we will not be liable for any
        breach of confidentiality or privacy of your information on such sites. We encourage you to learn
        about the privacy practices of those third parties.
      </p>
    </>
  );
};
const SocialMediaPlatforms = () => {
  return (
    <>
      <p>
        We may interact with registered users of various social media platforms, including Facebook,
        Twitter, Google+, LinkedIn and Instagram. Please note that any content you post to such social
        media platforms (e.g. pictures, information or opinions), as well as any personal information
        that you otherwise make available to users (e.g. your profile) is subject to the applicable social
        media platform’s terms of use and privacy policies. We recommend that you review this information
        carefully in order to better understand your rights and obligations with regard to such content.
      </p>
    </>
  );
};
const PromotionalMessages = () => {
  return (
    <>
      <p>
        We may sometimes contact you with products or services that we think may be of interest to you.
        If you don’t want to receive such promotional materials from us, you can opt out at any time by
        sending an email to <a href="mailto:support@monthly.ng">support@monthly.ng</a>
      </p>
    </>
  );
};
const PrivacyPolicyChanges = () => {
  return (
    <>
      <p>
        This policy may be revised on an ad-hoc basis to reflect the legal, regulatory and operating
        environment and such revised versions will automatically become applicable to you. We will
        post any revisions we make to our Privacy Policy on this page and such revised policy becomes
        effective as at the time it is posted. We will notify you when any changes to our privacy policy have
        been made. We also encourage you to check this page from time to time for updates to this policy.
      </p>
    </>
  );
};

export {
  WeValueYourPrivacy,
  InformationWeCollect,
  NonPersonalInformation,
  Cookies,
  UseOfPersonalInformation,
  AutomatedProcessing,
  InformationWeShare,
  HowWeProtectYourInformation,
  WhereWeStoreYourInformation,
  HowLongWeStoreYourInformation,
  YourRights,
  MaintainAccurateInformation,
  ThirdPartySitesAndServices,
  SocialMediaPlatforms,
  PromotionalMessages,
  PrivacyPolicyChanges
};
