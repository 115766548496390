// eslint-disable-next-line no-unused-vars
import React from "react";
export default () => {
  return (
    <>
      <section className="mission">
        <div className="container">
          <h3 className="text-bold my-4">Our <span className="text-success">Mission</span></h3>
          <div className="mission-text-body pb-4">
            Monthly’s mission is to help you thrive by financing the important things and allowing you pay
            with the frequency that works best for you. By taking care of the big responsibilities, we want
            to help you focus on what really matters, growing, evolving, and thriving in both your personal
            life and career.
          </div>
        </div>
      </section>
    </>
  );
};
