// eslint-disable-next-line no-unused-vars
import React from "react";
export default () => {
  return (
    <>
      <p>
        If you do not wish to be bound by this agreement, do not access the Monthly Website.
        If you have any questions about this Agreement, please contact us via e-mail
        at <a href="maito:support@monthly.ng">support@monthly.ng</a>
      </p>

      <p>
        THIS AGREEMENT "Agreement" is entered into by and between Instalment Solutions Limited
        and any individual, corporation, association, agency, company, or other entity who
        accesses or uses the Monthly Website (the "User" or "you").
      </p>

      <p>
        The Monthly Website, which is provided without charge to you, is a World Wide Web site
        on the Internet that is designed to allow Instalment Solutions Limited to communicate
        with users and for you to interact with the company. The Monthly Website is owned and
        operated by Instalment Solutions Limited. The Monthly Website contains or may contain
        information, communications, opinions, text, graphics, links, electronic art, animations,
        audio, video, software, photos, music, sounds and other material and data (collectively,
        "Content") formatted, organized and collected in a variety of forms that are generally
        accessible to Users, including directories and databases, and areas of the Monthly
        Website that can be modified by Users, such as uploading multimedia files, registering
        user profiles, and creating auto-notify, personalized pages, and personalized project areas.
      </p>

      <p>
        You are responsible for providing all hardware, software, telephone or other communications
        equipment and/or service to connect to the Internet and access the Monthly Website and are
        responsible for all Internet access charges, telephone charges or other fees or charges
        incurred in connecting to the Internet and accessing the Monthly Website.
      </p>

      <p>
        You agree to access and use the Monthly Website only for lawful purposes. You are solely
        responsible for the knowledge of and adherence to any and all laws, statutes, rules and
        regulations, pertaining to (i) your use of the Monthly Website, including any Interactive
        Area, (ii) the use of any networks or other services connected to the Monthly Website,
        and (iii) the communications means by which you connect your modem, computer, or other
        equipment to the Monthly Website. By accessing the Monthly Website, you agree that you will not:
      </p>
      <ul>
        <li>Restrict or inhibit any other user from using and enjoying the Interactive Features;</li>
        <li>
            Post or transmit any unlawful, threatening, abusive, libellous, defamatory, obscene, vulgar,
            pornographic, profane, or indecent information of any kind, including without limitation any
            transmissions constituting or encouraging conduct that would constitute a criminal offence,
            give rise to civil liability or otherwise violate any local, state, national,
            or international law;
        </li>
        <li>
            Post or transmit any information, software, or other material which violates or infringes
            in the rights of others, including material which is an invasion of privacy or publicity
            rights or which is protected by copyright, trademark or other proprietary right, or
            derivative works with respect thereto, without first obtaining permission from the owner
            or right holder.
        </li>
        <li>
            Post or transmit any information, software or other material which contains a virus or
            other harmful component;
        </li>
        <li>
            Alter, damage or delete any Content or other communications that are not your own Content
            or to otherwise interfere with the ability of others to access the Monthly Website;
        </li>
        <li>Disrupt the normal flow of communication in an Interactive Area;</li>
        <li>
            Claim a relationship with or to speak for any business, association, institution or other
            organization for which you are not authorized to claim such a relationship;
        </li>
        <li>
            Violate any operating rule, policy or guideline of your Internet access provider or online service.
        </li>
      </ul>

      <ol>
        <li>
            You acknowledge that Content on the Monthly Website is generally provided by Instalment
            Solutions Limited, individual contributors of Content ("Contributors"), third party licensees,
            and/or other Users. You acknowledge that the Monthly Website permits access to Content that
            is protected by copyrights, trademarks, and other proprietary (including intellectual property)
            rights ("Intellectual Property Rights"), and that these Intellectual Property Rights are valid
            and protected in all media existing now or later developed and except as is explicitly
            provided below, your use of Content shall be governed by applicable copyright and other
            intellectual property laws. You acknowledge that the Monthly Website owns a copyright in the
            "look and feel," i.e., the selection, coordination, arrangement and presentation of such Content.
        </li>
        <li>
            You may not modify, copy, reproduce, transmit, distribute, publish, create derivative works
            from, display or otherwise transfer or commercially exploit any of the Content, in whole or
            in part, provided, however, that you may (i) make a reasonable number of digital or other
            form of copies to permit your computer hardware and software to access and view the Content,
            (ii) print one copy of each piece of Content, (iii) make and distribute a reasonable number
            of copies of Content, in whole or in part, in hard copy or electronic form for internal use
            only. Any permitted copies of Content must reproduce in an unmodified form any notices
            contained in the Content, such as all Intellectual Property Right notices, and an original
            source attribution to "The Monthly Website" and its URL address. You acknowledge that the
            Monthly Website, its Contributors, and/or Users remain the owners of the Content and that
            you do not acquire any Intellectual Property Rights by downloading or printing Content.
        </li>
      </ol>

      <p>
        You acknowledge that the Monthly Website may include various interactive areas
        ("Interactive Areas"), including but not limited to classifieds. These Interactive Areas allow
        feedback to the Monthly Website and real-time interaction between users. You further understand
        that Instalment Solutions Limited does not control the messages, information, or files delivered
        to such Interactive Areas and that the Monthly Website may offer you and other Users the
        capability of creating and managing an Interactive Area. However, neither Instalment Solutions
        Limited, its subsidiary and parent companies, or affiliates, nor their respective directors,
        officers, employees and agents are responsible for Content within any Interactive Area. Your
        use and/or management of an Interactive Area will be governed by this Agreement and any
        additional rules or operating procedures of any Interactive Area established by you or another
        User, as applicable. You recognize that Instalment Solutions cannot, and does not intend to,
        screen communications in advance. Moreover, because the Monthly Website encourages open and candid
        communication in the Interactive Areas, Instalment Solutions Limited cannot determine in advance
        the accuracy or conformance to this Agreement of any Content transmitted in an Interactive Area.
        The Monthly Website is not responsible for screening, policing, editing, reviewing or monitoring
        any Content in an Interactive Area. Notwithstanding the above, you agree that Instalment Solutions
        Limited has the right to monitor any Interactive Area, from time to time and to disclose any
        information as necessary to satisfy any law, regulation or other governmental request, to
        operate the Interactive Area, or to protect itself or other Users. If notified of Content that
        is alleged not to conform to this Agreement, Instalment Solutions Limited may investigate the
        allegation and determine in its sole discretion whether to remove or request the User to remove
        such Content. Instalment Solutions Limited reserves the right to prohibit conduct, communication
        or Content within an Interactive Area, or to edit, refuse to post, or to remove any Content,
        in whole or in part, which it deems in its sole discretion to (i) violate the then-standard
        provisions of this Agreement or any other standard, written Monthly Website policy in effect
        at that time, (ii) be harmful to the rights of any User, Instalment Solutions Limited, or other
        third parties, (iii) violate applicable law, or (iv) be otherwise objectionable.
      </p>

      <p>
        The only right with respect to dissatisfaction with any policies, guidelines, or practices
        of Instalment Solutions Limited in operating the Monthly Website, or any change in Content,
        is for you to discontinue accessing the Monthly Website. Instalment Solutions Limited may
        terminate or temporarily suspend your access to all or any part of the Monthly Website, without
        notice, for conduct that Instalment Solutions Limited believes is a violation of this Agreement
        or any policies or guidelines posted by Instalment Solutions Limited, or for other conduct which
        Instalment Solutions Limited believes, in its sole discretion, is harmful to Instalment Solutions
        Limited or other Users. Instalment Solutions Limited may discontinue operating the Instalment
        Solutions Limited Website and terminate this Agreement without notice at any time for any reason
        in its sole discretion. In the event of termination, you are no longer authorized to access the
        Monthly Website, including the Interactive Areas, and the restrictions imposed on you with
        respect to Content downloaded from the Monthly Website, as well as the disclaimers and limitations
        of liabilities set forth in this agreement, shall survive.
      </p>

      <p>
        You understand that except for Content, products or services expressly available at the Monthly
        Website, neither Instalment Solutions Limited, its subsidiary and parent companies, or affiliates,
        or their respective directors, officers, employees, and agents controls, provides, or is
        responsible for any Content, goods or services available through sites on the Internet linked to
        or from the Monthly Website. All such Content, goods and services are made accessible on the
        Internet by independent third parties and are not part of the Monthly Website or controlled by
        Instalment Solutions Limited. Instalment Solutions Limited neither endorses nor is responsible
        for the accuracy, completeness, usefulness, quality or availability of any Content, goods or
        services available on any site linked to or from the Monthly Website, which are the sole
        responsibility of such independent third parties, and your use thereof is solely at your own risk.
        Neither Instalment Solutions Limited, its subsidiary and parent companies, or affiliates, or
        their respective directors, officers, employees, and agents shall be held responsible or liable,
        directly or indirectly, for any loss or damage caused or alleged to have been caused by your use
        of or reliance on any Content, goods or services available on any site linked to or from the
        Monthly Website or your inability to access the Internet or any site linked to or from the Monthly
        Website.
      </p>

      <p>
        Please use your best judgment in evaluating all information contained or opinions expressed on
        the Monthly Website. It is the policy of Instalment Solutions Limited not to endorse or oppose
        any opinion expressed by a User or Content provided by a User, Contributor, or other independent
        party. You expressly agree that your use of the Monthly Website is at your sole risk. neither
        Instalment Solutions Limited nor any of its subsidiary and parent companies, or affiliates, or
        their respective directors, officers, employees, agents, contractors, affiliates, licensors or
        other suppliers providing content, data, information or services warrants that the Monthly
        Website or any internet site linked to or from the Monthly Website will be uninterrupted or error
        free, that defects will be corrected, or that this site, including the interactive areas, or the
        server that makes it available are free of viruses or other harmful components. nor do any of
        them make any warranty as to the results that may be obtained from the use of the Monthly Website
        or any internet site linked to or from the Monthly Website or as to the timeliness, sequence,
        accuracy, authority, completeness, usefulness, non-infringement, reliability, availability, or
        substance of any content, information, service, or transaction provided through the Monthly
        Website or any site linked to or from the Monthly Website. The Monthly Website is provided on
        an "as is," "as available" basis, without warranties of any kind, either express or implied,
        including, without limitation, those of merchantability and fitness for a particular purpose.
      </p>

      <p>
        Under no circumstances shall Instalment Solutions Limited, its subsidiary and parent companies,
        or affiliates, or their respective directors, officers, employees, agents, contractors, or
        licensors, be liable for any direct or incidental, special or consequential damages under or
        arising from this agreement, the Monthly Website, or any Internet site linked to or from the
        Monthly Website, whether for breach of contract, tortuous behaviour, negligence, or under any
        other cause of action, including without limitation, any liability for damages caused or
        allegedly caused by any failure of performance, error, omission, interruption, electrical
        surge/damage/interference, deletion, defect, delay in operation or transmission, computer virus,
        communications line failure, breakdown of equipment, software error, infringement, unauthorized
        access to, or theft, destruction, alteration, or use of, records.
      </p>

      <p>
        Under no circumstances shall Instalment Solutions Limited, its subsidiary and parent companies,
        or affiliates, or their respective directors, officers, employees, agents, contractors, or
        licensors, be liable to you or any other third party for any decision made or action taken by
        you in reliance on the content contained within the Monthly Website or the content contained
        within any Internet site linked to or from the Monthly Website. The content within the Monthly
        Website and the content within Internet sites linked to or from the Monthly Website may include
        technical or other inaccuracies or typographical errors. Changes are periodically added to the
        content herein; these changes will be incorporated in new versions of the Monthly Website and
        specifically are included in this section agreement. Instalment Solutions Limited and its
        contributors may make improvements and/or changes in the content at any time and from time to time.
      </p>

      <p>
        You specifically acknowledge and agree that Instalment Solutions Limited is not liable for any
        defamatory, offensive, fraudulent, or otherwise illegal conduct of any user. If you are
        dissatisfied with any Monthly Website site content, or with the access agreement of the Monthly
        Website, in whole or in part, your sole and exclusive remedy is to discontinue using the Monthly
        Website.
      </p>

      <p>
        You agree to indemnify and hold Instalment Solutions Limited, its parent or subsidiary companies
        and their affiliates, and their respective directors, officers, employees, and agents from any
        and all liabilities, claims and expenses, including reasonable attorneys fees, arising from
        breach of this Agreement, any other policy, your use or access of the Monthly Website or any
        Internet site linked to or from the Monthly Website, or in connection with the transmission of
        any Content on the Monthly Website. This Agreement comprises the entire agreement between
        Instalment Solutions Limited and you, and supersedes any prior agreements with respect to the
        subject matter herein. Instalment Solutions Limited may revise this Agreement or any other policy
        at any time and from time to time, and such revision shall be effective two (2) days upon posting
        notice of such revision prominently on the Monthly Website. You agree to review this Agreement
        periodically to be aware of such revisions. If any such revision is unacceptable to you, you must
        discontinue accessing the Monthly Website.
      </p>

      <p>
        Your continued accessing and use of the Monthly Website following notice of any such revision
        shall conclusively be deemed acceptance of all such revisions. If any provision of this Agreement
        or any other policy be held invalid or unenforceable, that portion shall be construed in
        accordance with applicable law as nearly as possible to reflect the original intention of the
        parties and the remaining portions will continue in full force and effect. The failure of Instalment
        Solutions Limited to insist upon or enforce strict performance of any provision of this Agreement
        shall not be construed as a waiver of any provision or right. This Agreement shall be governed by
        the laws of the state or province of domicile of Instalment Solutions Limited, excluding its
        conflict of laws rules, and you and the Monthly Website each submit to the exclusive jurisdiction
        of the courts of that state or province.
      </p>

      <p>
        This Agreement is personal to you and you may not assign your rights or obligations thereunder
        to anyone. All logos, brand names, products, trademarks and service marks appearing herein may
        be the trademarks or service marks of their respective owners. References to any trademark,
        service mark and links to or from the Monthly Website have been done strictly for clarification
        and identification and does not constitute endorsement by Instalment Solutions Limited of the
        products, services or information offered by the owner of such trademark, service mark or link
        or endorsement of Instalment Solutions Limited by such trademark, service mark or link owner.
      </p>
    </>
  );
};
