// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
import { Accordion, Card, useAccordionButton, AccordionContext } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faClose } from "@fortawesome/free-solid-svg-icons";

function ContextAwareToggle({ eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <>
      {isCurrentEventKey &&
      <span className="accordion-toggle-icon">
        <span className="sr-only">Close</span>
        <FontAwesomeIcon icon={faClose} title="Close" onClick={decoratedOnClick} />
      </span>
      }
      {!isCurrentEventKey &&
      <span className="accordion-toggle-icon">
        <span className="sr-only">Expand</span>
        <FontAwesomeIcon icon={faPlus} title="Expand" onClick={decoratedOnClick} />
      </span>
      }
    </>
  );
}

export default (props) => {
  const { title, body, eventKey } = props;

  return (
    <>
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between">
          <div>{title}</div>
          <div className="float-end"><ContextAwareToggle eventKey={eventKey} /></div>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>{body}</Card.Body>
        </Accordion.Collapse>
      </Card>

    </>
  );
};
