// eslint-disable-next-line no-unused-vars
import React from "react";
import AboutMonthly from "./components/about/AboutMonthly";
import Mission from "./components/about/Mission";
import Tiles from "./components/about/Tiles";

export default () => {
  return (
    <>
      <AboutMonthly />
      <Mission />
      <Tiles />
    </>
  );
};
