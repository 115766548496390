import React, { useState } from "react";
import logo from "../assets/img/brand/logo.png";
import { useParams, Link } from "react-router-dom";
import HeroBanner from "../assets/img/transparent-hero.png";
import useValidator from "../hooks/useValidator";
import useAxiosProtected from "../hooks/useAxiosProtected";
import {
  Form
} from "@themesberg/react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const UPLOAD_URL_ENDPOINT = "/documents";
const KYC_COMPETE = "customers/kyc/complete";

export default function Upload() {
  const { token, type, loanId } = useParams();

  const [selectedFile, setSelectedFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newDocument, setNewDocument] = useState({});
  const [validator, showValidationMessage] = useValidator();
  const axiosProtected = useAxiosProtected(token);
  const DOCUMENT_TYPE = type;
  const DOCUMENT_LABEL = type.replaceAll("-", " ");
  const [fileName, setFileName] = useState("Upload pdf");
  const [isUploaded, setUploadStatus] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [terms, setTerms] = useState(false);

  const handleFormChange = (event) => {
    if (event.target.name === "terms") {
      setTerms(!terms);
    } else if (event.target.name === "isAgree") {
      setIsAgree(!isAgree);
    }
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }

    setNewDocument({ ...newDocument, [name]: value });
  };

  const uploadDocument = async () => {
    const controller = new AbortController();
    try {
      setIsSubmitting(true);
      const fileName = selectedFile.name.replaceAll(" ", "-");
      const pin = `Pin: ${newDocument.pin}`;

      const response = await axiosProtected.post(
        `${UPLOAD_URL_ENDPOINT}`,
        {
          type: DOCUMENT_TYPE,
          description: newDocument.description ?? `${pin}`,
          file_name: fileName,
          check_sum: "",
          resource_id: loanId
        },
        { signal: controller.signal }
      );

      if (response.status === 200 && DOCUMENT_LABEL === "bank statement") {
        axiosProtected
          .put(`${KYC_COMPETE}`, {
            consents: ["terms-and-conditions", "finance-provider-consent"]
          })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      }

      if (response.status === 200 && response.data.data) {
        const data = response.data.data;
        await axios.put(data.link, selectedFile, {
          headers: { "Content-Type": `${selectedFile.type}` }
        });
        setUploadStatus(true);
      } else {
        toast.error("Unable to get upload link");
      }
    } catch (e) {
      toast.error("Unable to upload, please try again");
    }
    setIsSubmitting(false);
    controller.abort();
  };
  const handleDocumentUpload = async () => {
    if (validator.allValid()) {
      await uploadDocument();
    } else {
      showValidationMessage(true);
    }
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    setIsSubmitting(false);
    hiddenFileInput.current.click();
  };

  return (
    <>
      <div className="new-signup-wrapper">
        <img src={HeroBanner} alt="" className="signup-bg-img" />
        <div className="new-upload-wrapper">
          <div className="top--logo">
            <img src={logo} alt="monthly logo" />
          </div>
          {type.toLowerCase().includes("selfie") ? (
            <span className=" upload--btn">
                            You are not authorized to upload file
            </span>
          ) : (
            <div>
              {!isUploaded ? (
                <Form className="upload--section">
                  <Form.Label className="doc--label" htmlFor="filename">{DOCUMENT_LABEL}</Form.Label>
                  <span className="upload--box" onClick={handleClick}>
                    {fileName}
                  </span>
                  <Form.Group className="upload--input">
                    <Form.Control
                      type="file"
                      accept="application/pdf"
                      name="file"
                      ref={hiddenFileInput}
                      required
                      onChange={handleFormChange}
                    />
                    {validator.message("file", newDocument.file, "required")}
                  </Form.Group>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label htmlFor="Pin">Pin (If applicable)</Form.Label>
                    <Form.Control
                      type="text"
                      id="pin"
                      placeholder="Pin"
                      name="pin"
                      onChange={handleFormChange}
                    />
                    {validator.message("pin", newDocument.pin, "string")}
                  </Form.Group>
                  {DOCUMENT_LABEL !== "additional" ? (
                    ""
                  ) : (
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label htmlFor="description">Description</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        id="description"
                        placeholder="description"
                        name="description"
                        onChange={handleFormChange}
                      />
                      {validator.message("description", newDocument.description, "required")}
                    </Form.Group>
                  )
                  }
                  {
                    DOCUMENT_LABEL === "bank statement" ? (
                      <div>
                        <div className="apply-checkbox">
                          <input
                            type="checkbox"
                            id="terms"
                            name="terms"
                            checked={terms}
                            onChange={handleFormChange}
                            value={terms}
                          />
                          <label htmlFor="terms">
                                                    I accept Monthly's KYC <Link to="/terms">Terms and Conditions</Link>
                          </label>
                        </div>
                        <div>
                          {validator.message("terms", newDocument.terms, "required")}
                        </div>
                        <div className="apply-checkbox">
                          <input
                            type="checkbox"
                            id="agree"
                            name="isAgree"
                            checked={isAgree}
                            onChange={handleFormChange}
                            value={isAgree}
                          />

                          <label htmlFor="agree">
                                                    I agree to Monthly’s finance provider using the information submitted to open an account for me
                          </label>
                        </div>
                        {validator.message("agree", newDocument.isAgree, "required")}
                      </div>
                    ) : (
                      ""
                    )
                  }

                  <button onClick={handleDocumentUpload} disabled={isSubmitting}
                    className="signup-button upload--btn"
                  >Proceed
                  </button>
                </Form>
              ) : (
                <div className="upload--section_msg">
                                Upload successful!
                  <a href="#" className="upload--btn">
                    {
                      DOCUMENT_LABEL === "bank statement" ? (
                        "Please log out and log back into the Monthly App to continue"
                      ) : (
                        "Please return to the Monthly App and refresh the loan screen"
                      )
                    }
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
}
