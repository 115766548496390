// eslint-disable-next-line no-unused-vars
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppRoutes } from "../routes";

export default () => {
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center not-found">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <div className="text-primary">
                  <FontAwesomeIcon icon={faRobot} className="me-3 ms-2 not-found-icon-medium" />
                  <FontAwesomeIcon icon={faRobot} className="me-3 ms-2 not-found-icon" />
                  <FontAwesomeIcon icon={faRobot} className="ms-2 not-found-icon-medium" />
                </div>
                <h1 className="mt-5 text-bold">
                  404
                </h1>
                <p className="lead mt-5 mb-5">
                  Oops! We can't find the page you're looking for.
                  Please click the button below to go back to the homepage.
                </p>
                <Button as={Link} variant="primary" size="lg" to={AppRoutes.Home.path}>
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
