// eslint-disable-next-line no-unused-vars
import React from "react";
import { Image } from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import M1 from "../../../assets/img/image1.png";
import M2 from "../../../assets/img/image2.png";
import M3 from "../../../assets/img/image3.png";
import M4 from "../../../assets/img/image4.png";
import M5 from "../../../assets/img/image5.png";
import M6 from "../../../assets/img/image6.png";

export default () => {
  return (
    <>
      <VerticalTimeline
        layout="1-column-left"
        lineColor="#C5C5C5"
        animate={false}
      >
        <VerticalTimelineElement
          iconStyle={{ background: "#fff", color: "#fff" }}
          icon={<Image src={M1} className="how-to-icon" />}
        >
          <h5>Tell us about yourself</h5>
          <p>
            We’ll need some information about you, including your name and your address.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          iconStyle={{ background: "#fff", color: "#fff" }}
          icon={<Image src={M2} className="how-to-icon" />}
        >
          <h5>Specify how much you need</h5>
          <p>
            Tell us how much you need and how long you want to repay for. You can borrow up to ₦5m to
            cover your rent expenses. Do let us know if you need help finding a place.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          iconStyle={{ background: "#fff", color: "#fff" }}
          icon={<Image src={M3} className="how-to-icon" />}
        >
          <h5>Apply and get your loan approved</h5>
          <p>
            We'll tell you the amount you can borrow, what you will need to repay and over how long.
            Our CBN regulated finance partner would also open an account for you.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          iconStyle={{ background: "#fff", color: "#fff" }}
          icon={<Image src={M4} className="how-to-icon" />}
        >
          <h5>Provide us with financing info </h5>
          <p>
            We’ll ask you to provide bank details so we can send your money
            to the right place and set up your direct debit.
            Sometimes we may require confirmation of address and tenancy.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: "#fff", color: "#fff" }}
          icon={<Image src={M5} className="how-to-icon" />}
        >
          <h5>Sign and get your loan paid</h5>
          <p>
            We send your rent directly to your landlord or agency.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: "#fff", color: "#fff" }}
          icon={<Image src={M6} className="how-to-icon" />}
        >
          <h5>Start your repayment </h5>
          <p>
            Pay your monthly due for the duration of time you applied for.
          </p>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </>
  );
};
