/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidator from "../../hooks/useValidator";
import useAuth from "../../hooks/useAuth";
import { getUserProfile } from "../../data/api";

export default function OTP() {
  const navigate = useNavigate();
  const location = useLocation();
  const passedState = location.state;
  const [isLoadingValidate, setIsLoadValidate] = useState(false);
  const [isCallInitiated, setCallInitiated] = useState(false);
  const [isLoadingRegenerate, setIsLoadingRegenerate] = useState(false);
  const [otpInput, setOTPInput] = useState("");
  const [editPhone, setEditPhone] = useState(false);
  const [firstFormValidator, showFirstValidationMessage] = useValidator(
    {}
  );
  const [formDetails, setFormDetail] = useState({
    email: passedState?.details?.email,
    phone_number: passedState?.details?.phoneNumber,
    password: passedState?.password
  });

  useEffect(() => {
    if (!passedState) {
      navigate("/signin");
    }
  });

  const {
    phone_number,
    email,
    password
  } = formDetails;
  const { setAuth, setUser } = useAuth();
  const handleOTPChange = (e) => {
    setOTPInput(e.target.value);
  };

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
  };

  const validateOTP = async (e) => {
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      const body = { phone_number, otp: otpInput };
      setIsLoadValidate(true);
      await axios
        .post(`${process.env.API_URL}/customers/verify-otp`, body)
        .then(function (response) {
          toast.success("Successfully Verified");
          if (response?.data) {
            toast.loading("Logging you in");
            // clearState();

            // Login into the app to get the token
            axios
              .post(`${process.env.API_URL}/oauth/token`, {
                username: email,
                password
              })
              .then((response) => {
                if (response.status === 200) {
                  setIsLoadValidate(false);
                  const accessToken = response?.data?.data?.access_token;
                  const refreshToken = response?.data?.data?.refresh_token;
                  setAuth({ accessToken, refreshToken });

                  getUserProfile(accessToken).then((profileResponse) => {
                    if (profileResponse?.status === 200) {
                      setUser(profileResponse?.data?.data);
                      localStorage.setItem(
                        "auth",
                        JSON.stringify({ accessToken, refreshToken })
                      );
                      setFormDetail({ ...formDetails, email: "", password: "", phone_number: "" });
                      navigate("/apply");
                    }
                  });
                }
              });
          }
        })
        .catch(function (error) {
          setIsLoadValidate(false);
          toast.error(`Couldnt validate OTP. ${error.response?.data?.message}`);
          setTimeout(() => {
            navigate("/signin");
          }, 2000);
        });
    } else {
      showFirstValidationMessage(true);
    }
  };

  const toggleEditPhone = () => {
    setEditPhone(!editPhone);
  };

  const generateOTP = async (e) => {
    e.preventDefault();
    setIsLoadingRegenerate(true);
    axios
      .post(
        `${process.env.API_URL}/customers/${email}/generate-otp/${phone_number}`
      )
      // eslint-disable-next-line no-unused-vars
      .then(function (response) {
        setIsLoadingRegenerate(false);
        setEditPhone(false);
        toast.success("OTP Generated");
      })
      .catch(function (error) {
        setIsLoadingRegenerate(false);
        toast.error(
          `Generate OTP gone wrong. ${error.response?.data?.message}`
        );
      });
  };

  const dictateOTP = async () => {
    setCallInitiated(true);
    axios
      .post(`${process.env.API_URL}/customers/${email}/dictate-otp`)
      // eslint-disable-next-line no-unused-vars
      .then(function (response) {
        setCallInitiated(false);
        toast.success("Call Initiated");
      })
      .catch(function (error) {
        setCallInitiated(false);
        toast.error(
          `Generate OTP gone wrong. ${error.response?.data?.message}`
        );
      });
  };

  return (
    <div className="page-container">
      <div className="apply-form-container">
        <form className="signup-form">
          <h5 className="new-signup-title">Enter code sent to</h5>
          <div className="new-signup-subtitle">
            {" "}
            {!editPhone ? (
              phone_number
            ) : (
              <div className="signup-formgroup">
                <input
                  type="tel"
                  name="phone_number"
                  value={phone_number}
                  onChange={handleFormChange}
                  maxLength={12}
                />
                <button
                  className="edit-phone-btn"
                  onClick={generateOTP}
                >
                  {isLoadingRegenerate ? "..." : "SAVE"}
                </button>
              </div>
            )}{" "}
            {!editPhone ? (
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={toggleEditPhone}
              />
            ) : null}
          </div>

          {!editPhone ? (
            <>
              <div className="signup-formgroup">
                <label>CODE</label>
                <input
                  type="text"
                  onChange={handleOTPChange}
                  name="otpInput"
                  value={otpInput}
                />
                {firstFormValidator.message(
                  "otp",
                  otpInput,
                  "required"
                )}
              </div>
              <button className="signup-button" onClick={validateOTP}>
                {isLoadingValidate ? "..." : "CONTINUE"}
              </button>
              <div className="otp-subfooter">
                {isLoadingRegenerate ? (
                  <div>Generating . . .</div>
                ) : (
                  <div onClick={generateOTP}>Resend Code</div>
                )}

                {isCallInitiated ? (
                  <div>Processing . . . </div>
                ) : (
                  <div onClick={dictateOTP}>
                    <FontAwesomeIcon icon={faPhone} /> Call me instead
                  </div>
                )}
              </div>
            </>
          ) : null}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}
