// eslint-disable-next-line no-unused-vars
import React, { useRef, useEffect, useCallback, useState } from "react";
import HeroBanner from "../../assets/img/transparent-hero.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  faAddressCard,
  faCircleCheck,
  faFileInvoiceDollar,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import useValidator from "../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import ApplyNavbar from "../components/ApplyNavbar";

const PRESIGNED_URL_ENDPOINT = "/documents";
const UPDATE_SELFIE_ENDPOINT = "/documents/upload-base64";
const KYC_COMPLETED = "customers/kyc/completed";
const KYC_COMPETE = "customers/kyc/complete";
const KYC_ENDPOINT = "/customers/kyc/profile";

export default function CompleteKYC() {
  const navigate = useNavigate();
  const [webCamState, setWebCamState] = useState(false);
  const axiosProtected = useAxiosProtected();
  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [isSelfieUploading, setIsSelfieUploading] = useState(false);
  const [formValidator, showValidationMessage] = useValidator({});
  const [selfieStatus, setSelfieStatus] = useState(false);
  const [statementStatus, setStatementStatus] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  // Get KYC Completed Status
  useEffect(() => {
    axiosProtected
      .get(`${KYC_COMPLETED}`)
      .then((res) => {
        if (res?.data?.data?.completed) {
          setIsTerms(true);
          setIsAgree(true);
        }
      })
      .catch((err) => {
        toast.err(err?.response?.data?.message);
      });

    const handleKyc = async () => {
      try {
        const response = await axiosProtected.get(KYC_ENDPOINT);

        const docs = response?.data?.data?.documents;
        if (docs) {
          for (let i = 0; i < docs?.length; i++) {
            if (docs[i]?.type === "selfie" && docs[i]?.status === "fail") {
              setSelfieStatus(false);
            } else if (docs[i]?.type === "selfie" && (docs[i]?.status === "pass" || docs[i]?.status === "pending")) {
              setSelfieStatus(true);
            }
            if (docs[i]?.type === "bank-statement" && docs[i]?.status === "fail") {
              setStatementStatus(false);
            } else if (docs[i]?.type === "bank-statement" && (docs[i]?.status === "pass" || docs[i]?.status === "pending")) {
              setStatementStatus(true);
            }
          }
        }
      } catch (error) {
        // toast.error(
        //   `User KYC Profile couldnt be returned ${error.response?.data?.message}`
        // );
      }
    };
    handleKyc();
  }, []);

  const handleOnChange = (e) => {
    if (e.target.name === "isTerms") {
      setIsTerms(!isTerms);
    } else if (e.target.name === "isAgree") {
      setIsAgree(!isAgree);
    }
  };

  const handleCompleteKyc = (e) => {
    e.preventDefault();
    if (formValidator.allValid()) {
      setIsLoading(true);
      axiosProtected
        .put(`${KYC_COMPETE}`, {
          consents: ["terms-and-conditions", "finance-provider-consent"]
        })
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          setIsLoading(false);
          navigate("/apply");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setIsLoading(false);
        });
    } else {
      showValidationMessage(true);
    }
  };

  // navigate to bank statement page
  const handleBankLink = () => {
    navigate("/apply/verify/bank");
  };

  const handleWebCamClick = () => setWebCamState(!webCamState);

  const handleRetake = () => setImgSrc(null);

  const uploadSelfie = async () => {
    try {
      setIsSelfieUploading(true);
      const response = await axiosProtected.post(`${PRESIGNED_URL_ENDPOINT}`, {
        type: "selfie",
        file_name: imgSrc
      });
      const data = response.data.data;
      if (response?.data?.status === 200) {
        const uploadresponse = await axiosProtected.post(
          `${UPDATE_SELFIE_ENDPOINT}`,
          {
            link: data.link,
            image: imgSrc
          }
        );
        if (uploadresponse?.status === 200) {
          setIsSelfieUploading(false);
          setWebCamState(false);
          toast.success("Document uploaded successfully", {
            autoClose: 1500,
            onClose: () => window.location.reload()
          });
        }
      } else {
        toast.error("Unable to get upload link");
      }
    } catch (error) {
      setIsSelfieUploading(false);
      toast.error(`Selfie uploaded. ${error.response?.data?.message}`);
    }
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef, setImgSrc]);

  const DisAbleCompleteBtn = () => {
    if (selfieStatus && statementStatus && isTerms && isAgree) {
      return false;
    }

    return true;
  };

  return (
    <div className="success-wrapper">
      <img src={HeroBanner} alt="" className="success-bg-img" />
      <div className="page-container">
        <ApplyNavbar />
        <div className="pending-container spacing-pending-container">
          <h2 className="text-center pb-3 mt-5">Complete KYC</h2>
          <div>
            <Modal show={webCamState} onHide={handleWebCamClick}>
              <Modal.Dialog>
                <Modal.Header closeButton>
                  <Modal.Title>Capture Selfie</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="cam-container">
                    {!imgSrc && webCamState ? (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        width={"100%"}
                        height={"100%"}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    ) : (
                      <img src={imgSrc} />
                    )}
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  {imgSrc ? (
                    <>
                      <Button
                        variant="secondary"
                        onClick={handleRetake}
                        disabled={isSelfieUploading}
                      >
                        Retake
                      </Button>
                      <Button
                        variant="primary"
                        disabled={isSelfieUploading}
                        onClick={uploadSelfie}
                      >
                        {isSelfieUploading ? "..." : "Done"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="secondary" onClick={handleWebCamClick}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={capture}>
                        Capture
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Modal.Dialog>
            </Modal>
          </div>

          <div className="card apply-card border-0">
            <button
              className="verify-btn mt-3 mb-3"
			  disabled={selfieStatus}
              onClick={handleWebCamClick}
            >
              <div className="d-flex gap-3">
                <div className="verify-icon">
                  <FontAwesomeIcon icon={faAddressCard} />
                </div>
                <div className="verify-text">
                  <h2>Upload Selfie</h2>{" "}
                  <p>Take a selfie to let us know who you are</p>
                </div>
              </div>

              <div style={{ fontSize: "20px" }}>
                {selfieStatus ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: "#17004a" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{ color: "#6c757d" }}
                  />
                )}
              </div>
            </button>
            {formValidator.message("selfieStatus", selfieStatus, "required")}

            <button className="verify-btn mt-3 mb-3" onClick={handleBankLink} disabled={statementStatus}>
              <div className="d-flex gap-3">
                <div className="verify-icon">
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </div>
                <div className="verify-text">
                  <h2>Bank Statement</h2>{" "}
                  <p>
                    Your account is safe and private. We simply want to verify
                    that your salary comes in as stated.
                  </p>
                </div>
              </div>

              <div style={{ fontSize: "20px" }}>
                {statementStatus ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: "#17004a" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{ color: "#6c757d" }}
                  />
                )}
              </div>
            </button>
            {formValidator.message(
              "statementStatus",
              statementStatus,
              "required"
            )}
          </div>
          <div className="apply-checkbox">
            <input
              type="checkbox"
              id="isTerms"
              name="isTerms"
              checked={isTerms}
              onChange={handleOnChange}
              value={isTerms}
            />
            <label htmlFor="">
              I accept Monthly's KYC <Link to="/terms">Terms and Conditions</Link>
            </label>
          </div>
          {formValidator.message("isTerms", isTerms, "required")}

          <div className="apply-checkbox">
            <input
              type="checkbox"
              id="isAgree"
              name="isAgree"
              checked={isAgree}
              onChange={handleOnChange}
              value={isAgree}
            />
            <label htmlFor="">
              I agree to Monthly’s finance provider using the information
              submitted to open an account for me
            </label>
          </div>
          {formValidator.message("isAgree", isAgree, "required")}
          <button
            className="signup-button complete-kyc-btn mt-5"
            // eslint-disable-next-line new-cap
            disabled={DisAbleCompleteBtn()}
            onClick={handleCompleteKyc}
          >
            {isLoading ? "..." : "COMPLETE KYC"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
