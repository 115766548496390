/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NigerianStates, IncomeSourceTypes, Months, Genders, MaritalStatus } from "../../utils/DropdownValues";
import {
  faExclamationCircle,
  faAdd,
  faMinus,
  faCircleLeft
} from "@fortawesome/free-solid-svg-icons";
import useValidator from "../../hooks/useValidator";
import axios from "axios";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import { Tooltip, OverlayTrigger } from "@themesberg/react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ApplyNavbar from "../components/ApplyNavbar";

const KYC_ENDPOINT = "/customers/kyc/profile";
const CUSTOMER_PROFILE_ENDPOINT = "/customers";
const LOAN_ENDPOINT = "/loans";

function ApplyForm() {
  const axiosProtected = useAxiosProtected();
  const navigate = useNavigate();
  const [kycResponse, setKycResponse] = useState({});
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isApplyingLoan, setIsApplyLoading] = useState(false);
  const [pageThreeValidator, showPageThreeMessage] = useValidator({});
  const [amountValidator, showAmountValMessage] = useValidator({});
  const [pageTwoValidator, showPageTwoMessage] = useValidator({});
  const [kycFormValidator, showKycValidationMessage] = useValidator({});
  const [isLoadingReferral, setIsLoadingReferral] = useState(false);
  const [referralStatus, setReferralStatus] = useState(true);
  const [referralName, setReferralName] = useState("");
  const [payment, setPayment] = useState({});
  const [pageState, setPageState] = useState(1);
  const [loan, setLoan] = useState([]);
  const [amountErr, setAmountErr] = useState(false);
  const [disableReferral, setDisableReferral] = useState(false);
  //   const [referralCode, setReferralCode]= useState("");

  const [income, setIncome] = useState([
    {
      employer_name: "",
      income_type: "",
      employer_address: "",
      industry: "",
      occupation: "",
      employment_start_date: "",
      net_amount: ""
    }
  ]);
  const [linkArr, setLinkArr] = useState([]);
  const [formDetails, setFormDetail] = useState({
    amount: "",
    number_of_dependents: 0,
    rental_state: "",
    current_loans: loan,
    rental_assistance_required: false,
    tenure_in_months: 0,
    income_sources: income,
    links: linkArr,
    frequency: "monthly",
    credit_search_authorized: true,
    calc_employment_type: "",
    rate: "",
    daily_rate: ""
  });

  const {
    amount,
    calc_employment_type,
    number_of_dependents,
    tenure_in_months,
    links
  } = formDetails;

  const getQuote = async (amt, tenure, income) => {
    axios
      .get(`${process.env.API_URL}/loans/quote`, {
        params: {
          amount: amt,
          tenure_in_months: tenure,
          income_source_type: income
        }
      })
      .then(function (response) {
        setPayment(response?.data?.data);
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function (error) {
        setPayment("\u20a60.00");
      });
  };

  const handleIncomeChange = async (e, index) => {
    const { name, value } = e.target;
    const allIncome = [...income];
    allIncome[index][name] = value;
    setIncome(allIncome);
    if (index === 0 && name === "income_type") {
      if (
        value === "part-time-employment" ||
        value === "full-time-employment" ||
        value === "contract-employment"
      ) {
        await getQuote(amount, tenure_in_months, "employed");
        setFormDetail({ ...formDetails, calc_employment_type: "employed", employment_type: value });
      } else {
        await getQuote(amount, tenure_in_months, "self-employed");
        setFormDetail({ ...formDetails, calc_employment_type: "self-employed", employment_type: value });
      }
    }
  };

  const verifyReferrals = (code) => {
    setIsLoadingReferral(true);
    setReferralStatus(true);
    axios
      .post(`${process.env.API_URL}/referrals/verify`, { referral_code: code })
      .then(function (response) {
        setIsLoadingReferral(false);
        if (response?.data) {
          setReferralName(response?.data?.data.referrer_name);
          setReferralStatus(response?.data?.data?.valid);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function (error) {
        setIsLoadingReferral(false);
        setReferralStatus(true);
      });
  };

  const handleLoanChange = (e, index) => {
    const { name, value } = e.target;
    const allLoans = [...loan];
    allLoans[index][name] = value;
    setLoan(allLoans);
  };

  const handleLinkChange = (e, index) => {
    const { name, value } = e.target;
    const allLinks = [...linkArr];
    allLinks[index][name] = value;
    setLinkArr(allLinks);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let isMounted = true;
    const handleKyc = async () => {
      try {
        const response = await axiosProtected.get(KYC_ENDPOINT);
        setKycResponse(response?.data?.data);
      // eslint-disable-next-line no-empty
      } catch (_error) {}
    };

    const handleProfile = async () => {
      try {
        const profileResponse = await axiosProtected.get(
          CUSTOMER_PROFILE_ENDPOINT
        );

        if (profileResponse?.data?.data?.referred_by) {
          setFormDetail({
            ...formDetails,
            referral_code: profileResponse?.data?.data?.referred_by
          });
          verifyReferrals(profileResponse?.data?.data?.referred_by);
          setDisableReferral(true);
        } else {
          setDisableReferral(false);
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          navigate("/signin");
        }
      }
    };

    handleKyc();
    handleProfile();

    return () => {
      isMounted = false;
    };
  }, []);

  const verifyKyc = async (e) => {
    e.preventDefault();
    if (kycFormValidator.allValid()) {
      setIsVerifyLoading(true);
      try {
        const response = await axiosProtected.put(
          "customers/kyc/profile",
          kycResponse
        );
        if (response.status === 202) {
          setPageState(2);
          setIsVerifyLoading(false);
        }
      } catch (err) {
        setIsVerifyLoading(false);
      }
    } else {
      showKycValidationMessage(true);
    }
  };

  useEffect(() => {
    setFormDetail({
      ...formDetails,
      income_sources: income,
      rate: payment?.rate,
      daily_rate: payment?.daily_rate
    });
  }, [income]);

  const handleLoanApplication = async (e) => {
    e.preventDefault();
    if (pageThreeValidator.allValid()) {
      setIsApplyLoading(true);
      try {
        const response = await axiosProtected.post(LOAN_ENDPOINT, formDetails);
        if (response?.status === 200) {
          // const data = response?.data?.data;
          setIsApplyLoading(false);
          navigate("/kyc");
        }
      } catch (error) {
        setIsApplyLoading(false);
        toast.error(
          `Error in Applying for the loan ${error.response?.data?.message}`
        );
      }
    } else {
      showPageThreeMessage(true);
    }
  };

  const AddIncomeSource = (e) => {
    e.preventDefault();
    const newIncome = [
      ...income,
      {
        employer_name: "",
        income_type: "",
        employer_address: "",
        industry: "",
        occupation: "",
        employment_start_date: "",
        net_amount: ""
      }
    ];
    setIncome(newIncome);
    setFormDetail({ ...formDetails, income: newIncome });
  };

  const AddOtherLoans = (e) => {
    e.preventDefault();
    const newLoan = [
      ...loan,
      {
        provider: "",
        balance: 0
      }
    ];
    setLoan(newLoan);
    setFormDetail({ ...formDetails, current_loans: newLoan });
  };

  const AddOtherLinks = (e) => {
    e.preventDefault();
    const newLink = [
      ...linkArr,
      {
        link: ""
      }
    ];
    setLinkArr(newLink);
    setFormDetail({ ...formDetails, links: newLink });
  };

  const DeleteLinkItem = (e, index) => {
    e.preventDefault();
    const newLink = linkArr.filter((item) => index !== linkArr.indexOf(item));
    setLinkArr(newLink);
    setFormDetail({ ...formDetails, links: newLink });
  };

  const DeleteLoanItem = (e, index) => {
    e.preventDefault();
    const newLoan = loan.filter((item) => index !== loan.indexOf(item));
    setLoan(newLoan);
    setFormDetail({ ...formDetails, current_loans: newLoan });
  };

  const DeleteIncomeItem = (e, index) => {
    e.preventDefault();
    const newIncome = income.filter((item) => index !== income.indexOf(item));
    setIncome(newIncome);
    setFormDetail({ ...formDetails, income: newIncome });
  };

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setKycResponse({ ...kycResponse, [name]: value });
  };

  const handleApplyChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
  };

  const handleApplyNext = (e) => {
    e.preventDefault();
    if (pageTwoValidator.allValid() && amountValidator.allValid()) {
      setPageState(3);
    } else {
      showPageTwoMessage(true);
      showAmountValMessage(true);
    }
  };
  const handlePageOne = () => setPageState(1);
  const handlePageTwo = () => setPageState(2);

  const handleDurationChange = async (event) => {
    const value = event.target.value;
    setFormDetail({ ...formDetails, tenure_in_months: value });

    // if there is an amount in the how much do you need field and it has not thrown an error
    // Quote on select duration dropdown

    if (amount && !amountErr) {
      await getQuote(amount, value, calc_employment_type);
    }
  };

  const handleAmountChange = async (e) => {
    const value = e.target.value;
    setFormDetail({ ...formDetails, amount: value });

    // If the amount you need is less thatn 200,000 or it is greater than 5million, call react validator.
    if (value > 5000000 || value < 200000) {
      setAmountErr(true);
      showAmountValMessage(true);
    } else {
      setAmountErr(false);
      await getQuote(value, tenure_in_months, calc_employment_type);
    }
  };

  const handleVerifyReferral = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
    if (value?.length > 4) {
      verifyReferrals(e.target.value);
    } else {
      setReferralStatus(true);
    }
  };

  const tooltip = (
    <Tooltip id="tooltip" className="bg-primary">
      <div className="d-flex">
        <p className="tooltip-text">
          Credit life insurance : {payment?.credit_insurance}
        </p>
      </div>
      <div className="d-flex">
        <p className="tooltip-text">
          Management fee: {payment?.management_fee}
        </p>
      </div>
    </Tooltip>
  );

  return (
    <div className="page-container">
      <ApplyNavbar />
      {pageState === 2 ? (
        <div className="apply-back-nav" onClick={handlePageOne}>
          <FontAwesomeIcon icon={faCircleLeft} /> Back
        </div>
      ) : pageState === 3 ? (
        <div className="apply-back-nav" onClick={handlePageTwo}>
          <FontAwesomeIcon icon={faCircleLeft} /> Back
        </div>
      ) : null}

      <div className="apply-form-container">
        <h4 className="text-center pb-3">Apply for a Loan</h4>
        {pageState === 1 ? (
          <form className="signup-form">
            <div className="signup-flexformgroup">
              <div className="signup-formgroup">
                <label>GENDER</label>
                <select
                  onChange={handleFormChange}
                  name="gender"
                  value={kycResponse?.gender}
                >
                  <option value=""> --- Select Gender --- </option>
                  {Genders.map((gender, i) => {
                    return (
                      <option value={gender.key} key={i}>
                        {gender.text}
                      </option>
                    );
                  })}
                </select>
                {kycFormValidator.message(
                  "gender",
                  kycResponse.gender,
                  "required"
                )}
              </div>

              <div className="signup-formgroup">
                <label>MARITAL STATUS</label>
                <select
                  onChange={handleFormChange}
                  name="marital_status"
                  value={kycResponse?.marital_status}
                >
                  <option value=""> --- Select Marital Status --- </option>
                  {MaritalStatus.map((status, i) => {
                    return (
                      <option value={status.key} key={i}>
                        {status.text}
                      </option>
                    );
                  })}
                </select>
                {kycFormValidator.message(
                  "marital_status",
                  kycResponse.marital_status,
                  "required"
                )}
              </div>
            </div>
            <div className="signup-formgroup">
              <label>DATE OF BIRTH</label>
              <input
                type="date"
                max="2005-01-01"
                name="dob"
                onChange={handleFormChange}
                value={kycResponse?.dob}
              />
              {kycFormValidator.message("dob", kycResponse?.dob, "required")}
            </div>
            <div className="signup-formgroup">
              <label>BVN (DIAL *565*0# TO GET YOUR BVN)</label>
              <input
                type="number"
                name="bvn"
                onChange={handleFormChange}
                value={kycResponse?.bvn}
              />
              {kycFormValidator.message("bvn", kycResponse.bvn, "required")}
            </div>
            <div className="signup-formgroup">
              <label>RESIDENTIAL ADDRESS</label>
              <input
                type="text"
                name="residential_address"
                onChange={handleFormChange}
                value={kycResponse?.residential_address}
              />
              {kycFormValidator.message(
                "residential_address",
                kycResponse.residential_address,
                "required"
              )}
            </div>
            <div className="signup-flexformgroup">
              <div className="small-formgroup">
                <label>AREA</label>
                <input
                  type="text"
                  name="area"
                  onChange={handleFormChange}
                  value={kycResponse.area}
                />
                {kycFormValidator.message(
                  "area",
                  kycResponse?.area,
                  "required"
                )}
              </div>
              <div className="signup-formgroup">
                <label>STATE</label>
                <select
                  onChange={handleFormChange}
                  name="state"
                  value={kycResponse.state}
                >
                  <option value="">--- Select Option ---</option>
                  {NigerianStates?.map((state) => {
                    return (
                      <option key={state?.key} value={state?.value}>
                        {state?.key}
                      </option>
                    );
                  })}
                </select>
                {kycFormValidator.message(
                  "state",
                  kycResponse.state,
                  "required"
                )}
              </div>
            </div>
            <div className="text-danger text-center mb-3">
              * Please do not falsify the information in this application as it
              is illegal *
            </div>
            <button className="signup-button" onClick={verifyKyc}>
              {isVerifyLoading ? "..." : " NEXT"}
            </button>
          </form>
        ) : pageState === 2 ? (
          <form className="signup-form">
            <div className="signup-formgroup">
              <label>HOW MUCH DO YOU NEED ?</label>
              <input
                type="number"
                min="50000"
                max="5000000"
                name="amount"
                onChange={handleAmountChange}
                value={amount}
              />
              {amountErr ?
                amountValidator.message(
                  "amount",
                  amount,
                  "required|numeric|min:200000,num|max:5000000,num"
                ) :
                null}
            </div>
            <div className="signup-formgroup">
              <label>DURATION</label>
              <select onChange={handleDurationChange} name="tenure_in_months">
                <option value="">--- Select Duration ---</option>
                {Months.map((month, i) => {
                  return (
                    <option key={i} value={month.value}>
                      {month.key}
                    </option>
                  );
                })}
              </select>
              {pageTwoValidator.message(
                "tenure_in_months",
                tenure_in_months,
                "required"
              )}
            </div>
            {payment?.monthly_repayment ? (
              <div className="card apply-calc-container mb-4">
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <h4>Repayment :</h4>
                  <h3>{payment?.monthly_repayment} / month</h3>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <h4>Total Repayment :</h4>
                  <h3>{payment?.total_repayment}</h3>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <h4>Interest Paid:</h4>
                  <h3>
                    {payment?.total_interest} ({payment?.interest_percent}%)
                  </h3>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <h4>
                    Loan Fee :{" "}
                    <OverlayTrigger placement="right" overlay={tooltip}>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </OverlayTrigger>{" "}
                  </h4>
                  <h3>{payment?.fee}</h3>
                </div>
              </div>
            ) : null}

            {income.map((item, index) => {
              return (
                <div key={index}>
                  {income?.length > 1 ? (
                    <button
                      className="text-danger income-btn"
                      onClick={(e) => {
                        // eslint-disable-next-line new-cap
                        DeleteIncomeItem(e, index);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} /> Remove this Income
                      source
                    </button>
                  ) : null}
                  <div className="signup-formgroup">
                    <label>INCOME TYPE</label>
                    <select
                      onChange={(e) => handleIncomeChange(e, index)}
                      name="income_type"
                    >
                      <option value="">--- Select Income Type ---</option>
                      {IncomeSourceTypes?.map((income, i) => {
                        return (
                          <option key={i} value={income.value}>
                            {income.key}
                          </option>
                        );
                      })}
                    </select>
                    {pageTwoValidator.message(
                      "income_type,",
                      income[index]["income_type"],
                      "required"
                    )}
                  </div>
                  {income[0]?.income_type === "self-employed" ? (
                    <div className="self-employed-error">
                      Loans to self employed are temporarily suspended, you can
                      still apply and we will automatically process when we
                      resume
                    </div>
                  ) : null}

                  <div className="signup-formgroup">
                    <label>EMPLOYER/BUSINESS NAME</label>
                    <input
                      type="text"
                      name="employer_name"
                      onChange={(e) => handleIncomeChange(e, index)}
                      value={item.employer_name}
                    />
                    {pageTwoValidator.message(
                      "employer_name",
                      income[index]["employer_name"],
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>EMPLOYER ADDRESS</label>
                    <input
                      type="text"
                      name="employer_address"
                      onChange={(e) => handleIncomeChange(e, index)}
                      value={item.employer_address}
                    />
                    {pageTwoValidator.message(
                      "employer_address",
                      income[index]["employer_address"],
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>INDUSTRY</label>
                    <input
                      type="text"
                      name="industry"
                      onChange={(e) => handleIncomeChange(e, index)}
                      value={item.industry}
                    />
                    {pageTwoValidator.message(
                      "industry",
                      income[index]["industry"],
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>JOB TITLE/OCCUPATION</label>
                    <input
                      type="text"
                      name="occupation"
                      onChange={(e) => handleIncomeChange(e, index)}
                      value={item.occupation}
                    />
                    {pageTwoValidator.message(
                      "occupation",
                      income[index]["occupation"],
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>START DATE</label>
                    <input
                      type="date"
                      name="employment_start_date"
                      onChange={(e) => handleIncomeChange(e, index)}
                      value={item.employment_start_date}
                    />
                    {pageTwoValidator.message(
                      "employment_start_date",
                      income[index]["employment_start_date"],
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>MONTHLY TAKE HOME PAY</label>
                    <input
                      type="number"
                      name="net_amount"
                      onChange={(e) => handleIncomeChange(e, index)}
                      value={item.net_amount}
                    />
                    {pageTwoValidator.message(
                      "net_amount",
                      income[index]["net_amount"],
                      "required"
                    )}
                  </div>
                </div>
              );
            })}
            <button
              className="text-primary income-btn mb-3"
              onClick={AddIncomeSource}
            >
              <FontAwesomeIcon icon={faAdd} /> Add Income{" "}
            </button>

            <div className="text-center text-danger">
              *Please do not falsify the information in this application as it
              is illegal*
            </div>

            <button className="signup-button" onClick={handleApplyNext}>
              NEXT
            </button>
          </form>
        ) : pageState === 3 ? (
          <form className="signup-form">
            {payment?.monthly_repayment ? (
              <div className="card apply-calc-container mb-4">
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <h4>Loan Amount :</h4>
                  <h3>{payment?.amount}</h3>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <h4>Duration :</h4>
                  <h3>{payment?.tenure_in_months} months</h3>
                </div>
              </div>
            ) : null}
            <div className="signup-formgroup">
              <label>WHAT STATE ARE YOU RENTING IN? ?</label>
              <select onChange={handleApplyChange} name="rental_state">
                <option value="">--- Select Option ---</option>
                {NigerianStates?.map((state) => {
                  return (
                    <option key={state?.key} value={state?.value}>
                      {state?.key}
                    </option>
                  );
                })}
              </select>
              {pageThreeValidator.message(
                "rental_state",
                formDetails.rental_state,
                "required"
              )}
            </div>

            <div className="signup-formgroup">
              <label>DO YOU HAVE ANY CHILDREN OR DEPENDANTS ?</label>
              <input
                type="text"
                name="number_of_dependents"
                onChange={handleApplyChange}
                value={number_of_dependents}
              />
              {pageThreeValidator.message(
                "number_of_dependents",
                formDetails.number_of_dependents,
                "required"
              )}
            </div>
            <div className="signup-formgroup">
              {loan?.length > 0 ? (
                <label>Are you currently paying back any loans?</label>
              ) : null}
            </div>

            {loan?.map((item, index) => {
              return (
                <div key={index}>
                  {loan.length > 0 ? (
                    <button
                      className="text-danger income-btn"
                      onClick={(e) => {
                        // eslint-disable-next-line new-cap
                        DeleteLoanItem(e, index);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} /> Remove this Loan
                    </button>
                  ) : null}
                  <div className="signup-flexformgroup">
                    <div className="small-formgroup">
                      <input
                        type="text"
                        name="provider"
                        onChange={(e) => handleLoanChange(e, index)}
                        value={item.provider}
                        placeholder="Loan Provider"
                      />
                    </div>
                    <div className="small-formgroup">
                      <input
                        type="number"
                        name="balance"
                        onChange={(e) => handleLoanChange(e, index)}
                        value={item.balance}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              className="text-primary income-btn mb-3"
              onClick={AddOtherLoans}
            >
              <FontAwesomeIcon icon={faAdd} />{" "}
              {loan?.length > 0 ? "Add More" : "Are You Paying Back Any loan ?"}
            </button>

            {linkArr?.map((item, index) => (
              <div key={index}>
                {links.length > 0 ? (
                  <button
                    className="text-danger income-btn"
                    onClick={(e) => {
                      // eslint-disable-next-line new-cap
                      DeleteLinkItem(e, index);
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} /> Remove this Link
                  </button>
                ) : null}
                <div className="signup-formgroup">
                  <label>WEB LINKS (OPTIONAL)</label>
                  <input
                    type="text"
                    name="link"
                    onChange={(e) => handleLinkChange(e, index)}
                    value={item.link}
                  />
                </div>
              </div>
            ))}
            <button
              className="text-primary income-btn mb-3"
              onClick={AddOtherLinks}
            >
              <FontAwesomeIcon icon={faAdd} />{" "}
              {links?.length > 0 ?
                "Add More" :
                "Would you like to add a web link ?"}
            </button>

            <div className="signup-formgroup">
              <label>DO YOU NEED HELP FINDING A PLACE?</label>
              <select
                onChange={handleApplyChange}
                name="rental_assistance_required"
              >
                <option value="">--- Select Option ---</option>
                <option value="true"> Yes </option>
                <option value="false"> No </option>
              </select>
            </div>
            <div className="signup-formgroup">
              <label>REFERRAL/PROMO CODE (OPTIONAL) </label>
              <input
                type="text"
                name="referral_code"
                onChange={handleVerifyReferral}
                value={formDetails?.referral_code}
                disabled={disableReferral}
              />
              <p className="success-tag">{isLoadingReferral ? "..." : null}</p>
              {!referralStatus ? (
                <p className="error-tag"> Invalid Referral Code</p>
              ) : null}
              {referralName ? (
                <p className="success-tag">
                  <span>Referred By : </span>
                  {referralName}
                </p>
              ) : null}
            </div>

            <button className="signup-button" onClick={handleLoanApplication}>
              {isApplyingLoan ? "..." : "Apply"}
            </button>
          </form>
        ) : null}
      </div>
      <ToastContainer />
    </div>
  );
}

export default ApplyForm;
