// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import useAxiosProtected from "../../../hooks/useAxiosProtected";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ApplyNavbar from "../../components/ApplyNavbar";
import { useNavigate } from "react-router-dom";
import useValidator from "../../../hooks/useValidator";

function VerifyBank() {
  const axiosProtected = useAxiosProtected();
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [statementStatus, setStatementStatus] = useState(false);
  const [formValidator, showValidationMessage] = useValidator({});
  const [pin, setPin] = useState("");

  const KYC_ENDPOINT = "/customers/kyc/profile";
  const PRESIGNED_URL_ENDPOINT = "/documents";

  const navigate = useNavigate();

  // Get KYC Completed Status
  useEffect(() => {
    const handleKyc = async () => {
      try {
        const response = await axiosProtected.get(KYC_ENDPOINT);

        const docs = response?.data?.data?.documents;
        if (docs) {
          for (let i = 0; i < docs?.length; i++) {
            if (docs[i]?.type === "bank-statement" && docs[i]?.status === "fail") {
              setStatementStatus(false);
            } else if (docs[i]?.type === "bank-statement" && (docs[i]?.status === "pass" || docs[i]?.status === "pending")) {
              setStatementStatus(true);
            }
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    };
    handleKyc();
  }, []);

  // const widgetOkra = () => {
  //   Okra.buildWithOptions({
  //     name: "Monthly",
  //     env: process.env.ENVIRONMENT === "staging" ? "sandbox" : "production",
  //     app_id: "", // app_id from your app builder
  //     key: process.env.OKRA_KEY,
  //     token: process.env.OKRA_TOKEN,
  //     products: ["auth", "identity", "balance", "transactions", "income"],
  //     onSuccess: function (data) {
  //       axiosProtected
  //         .post(`${OKRA_CONNECTED}`, {
  //           record: data?.record,
  //           customer_id: data?.customer_id,
  //           status: data?.accounts[0]?.status,
  //           account: data?.accounts[0]?.accountId,
  //           metadata: data,
  //         })
  //         .then((res) => console.log(res))
  //         .catch((err) => console.log(err));
  //       setTimeout(() => navigate("/kyc"), 500);
  //     },
  //     onClose: function () {
  //       navigate("/kyc");
  //       setTimeout(()=>window.location.reload(), 500)
  //     },
  //   });
  // };

  // const handleWidget = (e) => {
  //   e.preventDefault();
  //   widgetOkra();
  // };

  const handleFileChange = (event) => {
    const target = event.target;
    setSelectedFile(target.files[0]);
  };

  const handlePinChange = (e) => {
    const { value } = e.target;
    setPin(value);
  };

  const uploadDocument = async (e) => {
    e.preventDefault();
    if (formValidator.allValid()) {
      setIsSubmitting(true);
      const fileName = selectedFile.name.replaceAll(" ", "-");
      const response = await axiosProtected.post(`${PRESIGNED_URL_ENDPOINT}`, {
        type: "bank-statement",
        description: `A manually uploaded Bank Statement, Pin: ${pin}`,
        file_name: fileName
      });
      if (response.data.status === 200 && response.data.data) {
        const data = response.data.data;
        await axios.put(data.link, selectedFile, {
          headers: { "Content-Type": `${selectedFile.type}` }
        });
        toast.success("Document Uploaded successfully");
        navigate("/kyc");
        setTimeout(() => window.location.reload(), 500);
      } else {
        toast.error("Unable to get upload link");
      }

      setIsSubmitting(false);
    } else {
      showValidationMessage(true);
    }
  };

  return (
    <div className="page-container">
      <ApplyNavbar />
      <div className="apply-form-container">
        <h4 className="text-center pb-3">Manual Upload</h4>
        <form className="signup-form">
          <div className="signup-formgroup">
            <label>BANK STATEMENT</label>
            <div className="file-upload-container">
              <input
                type="file"
                accept="application/pdf"
                required
                onChange={handleFileChange}
                disabled={statementStatus}
              />
              {statementStatus ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#17004a" }}
                />
              ) : (
                <FontAwesomeIcon icon={faUpload} style={{ color: "#6c757d" }} />
              )}
            </div>
          </div>
          <div className="signup-formgroup">
            <label>DOCUMENT PIN (If required to open)</label>
            <div className="file-upload-container">
              <input
                type="text"
                onChange={handlePinChange}
                name="pin"
                value={pin}
              />
            </div>
          </div>
          {/* <br />
          <h2 className="text-center">OR</h2>
          <br />
          <button
            className="d-flex align-items-center justify-content-between bg-light border-0 p-3 mt-2 mb-1 w-100 rounded-0"
            onClick={handleWidget}
            disabled={statementStatus}
          >
            <span>Get Statement Automatically via internet banking</span>
            {statementStatus ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: "#17004a" }}
              />
            ) : (
              <FontAwesomeIcon icon={faWarning} style={{ color: "#6c757d" }} />
            )}
          </button>
          {formValidator.message("Upload File", selectedFile, "required")} */}

          <button
            className="signup-button"
            onClick={uploadDocument}
            disabled={statementStatus}
          >
            {IsSubmitting ? "..." : "UPLOAD"}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default VerifyBank;
