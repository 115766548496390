// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function NewCalc() {
  const [duration, setDuration] = useState(12);
  const [income, setIncome] = useState("employed");
  const [amount, setAmount] = useState(0);
  const [payment, setPayment] = useState("\u20a60.00");
  const [storedPayment, setStoredPayment] = useState(0);

  useEffect(() => {
    localStorage.setItem("employment_type", income);
    localStorage.setItem("duration", duration);
    localStorage.setItem("loan_amount", amount);
    localStorage.setItem("repayment_amount", storedPayment);
  }, []);

  const INCOME_SOURCE = [
    { name: "Employed", value: "employed" },
    { name: "Self Employed", value: "self-employed" }
  ];

  const MONTHS = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  const getQuote = async (amt, tenure, income) => {
    axios
      .get(`${process.env.API_URL}/loans/quote`, {
        params: {
          amount: amt,
          tenure_in_months: tenure,
          income_source_type: income
        }
      })
      .then(function (response) {
        setPayment(response?.data?.data?.monthly_repayment);
        setStoredPayment(response?.data?.data?.unformatted_monthly_repayment);
        localStorage.setItem(
          "repayment_amount",
          response?.data?.data?.unformatted_monthly_repayment
        );
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function (error) {
        setPayment("\u20a60.00");
        setStoredPayment(0);
      });
  };

  const handleIncomeChange = async (event) => {
    const value = event.target.value;
    setIncome(value);
    localStorage.setItem("employment_type", value);
    await getQuote(amount, duration, value);
  };

  const handleDurationChange = async (event) => {
    const value = event.target.value;
    setDuration(value);
    localStorage.setItem("duration", value);
    await getQuote(amount, value, income);
  };

  const handleAmountChange = async (event) => {
    const value = event.target.value;
    setAmount(value);
    localStorage.setItem("loan_amount", value);
    await getQuote(value, duration, income);
  };

  return (
    <div className="new-calc-form">
      <div className="calc-formgroup">
        <label>HOW DO YOU MAKE MONEY</label>
        <select onChange={handleIncomeChange} name="medium">
          {INCOME_SOURCE.map((income, i) => {
            return (
              <option key={i} value={income.value}>{income.name}</option>
            );
          })}
        </select>
      </div>
      <div className="calc-formgroup">
        <label>ENTER LOAN AMOUNT</label>
        <input type="number" name="amount" onChange={handleAmountChange} value={amount} />
      </div>
      <div className="calc-formgroup">
        <label>ENTER REPAYMENT PERIOD (MONTHS)</label>
        <select onChange={handleDurationChange} name="medium">
          {MONTHS.map((month, i) => {
            return (
              <option key={i} value={month}>{month}</option>
            );
          })}
        </select>
      </div>
      <div className="calc-result">
        <p>You Pay</p>
        <h2>{payment}</h2>
      </div>
    </div>
  );
}
