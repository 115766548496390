// eslint-disable-next-line no-unused-vars
import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { AppRoutes } from "./routes";

// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Faqs from "./pages/Faqs";
import HowItWorks from "./pages/HowItWorks";
import NotFound from "./pages/NotFound";
// import Register from './pages/Register';
import MarketingHome from "./pages/MarketingHome";
import AgentHome from "./pages/AgentHome";
import Signup from "./pages/auth/SignUp";
import AgentSignup from "./pages/agent/AgentSignup";
import Upload from "./pages/Upload";
import SuccessAgent from "./pages/agent/SuccessAgent";
import SignIn from "./pages/auth/SignIn";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ApplyForm from "./pages/loan/ApplyForm";
import CompleteKYC from "./pages/loan/CompleteKYC";
import OTPResend from "./pages/auth/OTP";

// These pages and routes have been made, they are just commented out on the interim

// import Guarantor from './pages/guarantor/Guarantor';
// import EmployerGuarantor from './pages/guarantor/Employer';
// import EmployeeGuarantor from './pages/guarantor/Employee';
// import VerifyID from './pages/verify/VerifyID';
import VerifyBank from "./pages/loan/verify/VerifyBank";
import AdditionalDocs from "./pages/loan/AdditionalDocs";
// import VerifyDoc from './pages/verify/VerifyDoc';
// components
import Navbar from "./pages/components/NewNavbar";
import Footer from "./pages/components/home/Footer";
import Apply from "./pages/loan/Apply";
import RequireAuth from "./components/RequireAuth";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default () => (
  <Routes>
    <Route path={AppRoutes.Signup.path} element={<Signup />} />
    <Route path={AppRoutes.AgentSignup.path} element={<AgentSignup />} />
    <Route path={AppRoutes.SuccessAgent.path} element={<SuccessAgent />} />
    <Route path={AppRoutes.AgentRegister.path} element={<AgentHome />} />
    <Route path={AppRoutes.AgentHome.path} element={<AgentHome />} />
    <Route path={AppRoutes.MarketingHome.path} element={<MarketingHome />} />
    <Route path={AppRoutes.Register.path} element={<MarketingHome />} />
    <Route path={AppRoutes.Signin.path} element={<SignIn />} />
    <Route path={AppRoutes.ResendOTP.path} element={<OTPResend />} />
    {/* <Route element={<PersistLogin/>}> */}
    {/* <Route element={<RequireAuth/>}> */}
    <Route path={AppRoutes.Apply.path} element={<RequireAuth><Apply /></RequireAuth>} />
    <Route path={AppRoutes.ApplyForm.path} element={<RequireAuth><ApplyForm /></RequireAuth>} />
    {/* <Route path={AppRoutes.ApplyPending.path} element={<RequireAuth><ApplyPending/></RequireAuth>}/> */}
    <Route path={AppRoutes.CompleteKyc.path} element={<RequireAuth><CompleteKYC /></RequireAuth>} />
    <Route path={AppRoutes.AdditionalDocs.path} element={<RequireAuth><AdditionalDocs /></RequireAuth>} />
    {/* <Route path={AppRoutes.Guarantor.path} element={<Guarantor/>}/> */}
    {/* <Route path={AppRoutes.EmployerGuarantor.path} element={<EmployerGuarantor/>}/> */}
    {/* <Route path={AppRoutes.EmployeeGuarantor.path} element={<EmployeeGuarantor/>}/> */}
    {/* <Route path={AppRoutes.VerifyID.path} element={<VerifyID/>}/> */}
    <Route path={AppRoutes.VerifyBank.path} element={<VerifyBank />} />
    {/* // <Route path={AppRoutes.VerifyDoc.path} element={<VerifyDoc/>}/> */}
    {/* </Route> */}

    {/* </Route> */}

    <Route path={AppRoutes.ForgotPassword.path} element={<ForgotPassword />} />
    <Route path={AppRoutes.Upload.path}>
      <Route path=":loanId" element={<Upload />} />
      <Route path="" element={<Upload />} />
    </Route>

    <Route path="/" element={<Layout />}>
      <Route path={AppRoutes.Home.path} element={<Home />} />
      <Route path={AppRoutes.About.path} element={<About />} />
      <Route path={AppRoutes.Privacy.path} element={<Privacy />} />
      <Route path={AppRoutes.Terms.path} element={<Terms />} />
      <Route path={AppRoutes.Faqs.path} element={<Faqs />} />
      <Route path={AppRoutes.HowItWorks.path} element={<HowItWorks />} />
      <Route path={AppRoutes.NotFound.path} element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);
