/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import logo from "../../assets/img/brand/logo.png";
import HeroBanner from "../../assets/img/transparent-hero.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPhone, faArrowAltCircleLeft, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidator from "../../hooks/useValidator";

// import useValidator from '../hooks/useValidator';

const SignUp = () => {
  const navigate = useNavigate();
  const storedReferral = localStorage.getItem("refer");
  const [formDetails, setFormDetail] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    channel: "web",
    referred_by: storedReferral
  });
  const [formState, setFormState] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingValidate, setIsLoadValidate] = useState(false);
  const [isCallInitiated, setCallInitiated] = useState(false);
  const [isLoadingRegenerate, setIsLoadingRegenerate] = useState(false);
  const [isLoadingReferral, setIsLoadingReferral] = useState(false);
  const [otpInput, setOTPInput] = useState("");
  const [referralName, setReferralName] = useState("");
  const [referralStatus, setReferralStatus] = useState(true);
  const [editPhone, setEditPhone] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const customValidator = {
    password: {
      message: "Password should be at least 8 characters, contain a lowercase, uppercase and a number",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/);
      },
      required: true
    }
  };
  const [firstFormValidator, showFirstValidationMessage] = useValidator({}, customValidator);

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
  };

  const verifyReferrals = (code) => {
    setIsLoadingReferral(true);
    setReferralStatus(true);
    axios.post(`${process.env.API_URL}/referrals/verify`, { referral_code: code })
      .then(function (response) {
        setIsLoadingReferral(false);
        if (response?.data) {
          setReferralName(response?.data?.data.referrer_name);
          setReferralStatus(response?.data?.data?.valid);
        }
        if (response?.data?.data.referrer_name?.length) {
          setFormDetail({ ...formDetails, referred_by: storedReferral });
        }
      })
      .catch(function (error) {
        setIsLoadingReferral(false);
        setReferralStatus(true);
        toast.error(
          `Couldnt validate Referral Code. ${error.response?.data?.message}`
        );
      });
  };

  const toggleEditPhone = () => {
    setEditPhone(!editPhone);
  };

  useEffect(() => {
    if (storedReferral) {
      verifyReferrals(storedReferral);
    }
  }, []);

  const handleOTPChange = (e) => {
    setOTPInput(e.target.value);
  };

  const handleSecondForm = (e) => {
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      setFormState(2);
    } else {
      showFirstValidationMessage(true);
    }
  };

  const handleBackFormTwo = (e) => {
    e.preventDefault();
    setFormState(1);
  };

  // const handleBackFormThree = (e) => {
  //   e.preventDefault();
  //   setFormState(2);
  // };

  const { first_name, last_name, phone_number, email,
    password, referred_by } = formDetails;
  const clearState = () => {
    setFormDetail({ ...formDetails,
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      channel: "web",
      referred_by: "" });
  };

  const generateOTP = async (e) => {
    e.preventDefault();
    setIsLoadingRegenerate(true);
    axios.post(`${process.env.API_URL}/agents/${email}/generate-otp/${phone_number}`)
      // eslint-disable-next-line no-unused-vars
      .then(function (res) {
        setIsLoadingRegenerate(false);
        setEditPhone(false);
        toast.success("OTP Generated");
      })
      .catch(function (error) {
        setIsLoadingRegenerate(false);
        toast.error(
          `Generate OTP gone wrong. ${error.response?.data?.message}`
        );
      });
  };

  const dictateOTP = async () => {
    setCallInitiated(true);
    axios.post(`${process.env.API_URL}/agents/${email}/dictate-otp`)
      // eslint-disable-next-line no-unused-vars
      .then(function (res) {
        setCallInitiated(false);
        toast.success("Call Initiated");
      })
      .catch(function (error) {
        setCallInitiated(false);
        toast.error(
          `Generate OTP gone wrong. ${error?.response?.data?.message}`
        );
      });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      setIsLoading(true);
      axios.post(`${process.env.API_URL}/agents`, formDetails)
        .then(function (response) {
          localStorage.setItem("agent-referral", response?.data?.data?.referral_code);
          setIsLoading(false);
          setFormState(3);
        })
        .catch(function (error) {
          setIsLoading(false);
          toast.error(
            `Couldnt signup. ${error?.response?.data?.message}`
          );
        });
    } else {
      showFirstValidationMessage(true);
    }
  };

  const handleVerifyReferral = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
    if (value?.length > 4) {
      verifyReferrals(e.target.value);
    } else {
      setReferralStatus(true);
    }
  };

  const validateOTP = async (e) => {
    e.preventDefault();
    const body = { phone_number, otp: otpInput };
    setIsLoadValidate(true);
    axios.post(`${process.env.API_URL}/agents/verify-otp`, body)
      .then(function (response) {
        setIsLoadValidate(false);
        if (response?.data) {
          clearState();
          navigate("/agents/signup/success");
        }
      })
      .catch(function (error) {
        setIsLoadValidate(false);
        toast.error(
          `Couldnt validate OTP. ${error.response?.data?.message}`
        );
      });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <>
      <div className="new-signup-wrapper">
        <img src={HeroBanner} alt="" className="signup-bg-img" />
        <div className="new-signup-container">
          <div className="marketing-navbar">
            <img src={logo} alt="monthly logo" />
          </div>
          <div className="new-signup-section">
            <div className="signup-left">
              <h2>Close more rentals and <span>earn more</span></h2>
              {/* <div className="sub-signup-left">
                <div className="trusted-button">
                  <img src={Trusted} alt="Trusted icon"/>
                  <h6>Trusted by agents like <span>you</span></h6>
                </div>
                {
                  formState === 1 ? (
                    <>
                      <div className="signup-review">
                          “Monthly’s hassle free Rental Loan System is a revolution.
                          They offer subsidised rates on interest for tenants which
                          brings relief to our clients”
                      </div>
                      <div className="review-avatar">
                        <img src={ReviewAvatar4} alt="review-avatar"/>
                        <h4>Peter, Lobia Properties</h4>
                      </div>
                    </>

                  ) : formState === 2 ? (
                    <>
                      <div className="signup-review">
                         “Monthly’s hassle free Rental Loan System is a revolution.
                         They offer subsidised rates on interest for tenants which
                         brings relief to our clients”
                      </div>
                      <div className="review-avatar">
                        <img src={ReviewAvatar4} alt="review-avatar"/>
                        <h4>Peter, Lobia Properties</h4>
                      </div>
                    </>
                  ) : formState === 3 ? (
                    <>
                      <div className="signup-review">
                         “Monthly’s hassle free Rental Loan System is a revolution.
                         They offer subsidised rates on interest for tenants which
                         brings relief to our clients”
                      </div>
                      <div className="review-avatar">
                        <img src={ReviewAvatar4} alt="review-avatar"/>
                        <h4>Peter, Lobia Properties</h4>
                      </div>
                    </>
                  ) : null
                }
              </div> */}
            </div>
            <div className="signup-right">
              <h1 className="mobile-signup-header">Close more rentals and
                <span>{" "}earn more</span>
              </h1>

              {formState === 1 ? (
                <form className="signup-form">
                  <div className="signup-formgroup">
                    <label>FIRST NAME</label>
                    <input type="text" name="first_name" onChange={handleFormChange} value={first_name} />
                    {firstFormValidator.message("first_name", formDetails.first_name, "required")}
                  </div>
                  <div className="signup-formgroup">
                    <label>LAST NAME</label>
                    <input type="text" name="last_name" onChange={handleFormChange} value={last_name} />
                    {firstFormValidator.message("last_name", formDetails.last_name, "required")}
                  </div>
                  <div className="signup-formgroup">
                    <label>EMAIL ADDRESS</label>
                    <input type="email" name="email" onChange={handleFormChange} value={email} />
                    {firstFormValidator.message("email", formDetails.email, "required|email")}
                  </div>
                  <div className="signup-formgroup">
                    <label>PHONE NUMBER</label>
                    <input type="tel" name="phone_number" maxLength={12} onChange={handleFormChange}
                      value={phone_number}
                    />
                    {firstFormValidator.message("phone_number", formDetails.phone_number, "required|max:12")}
                  </div>
                  <button className="signup-button" onClick={handleSecondForm}>CONTINUE</button>
                </form>
              // eslint-disable-next-line no-nested-ternary
              ) : formState === 2 ? (

                <form className="signup-form">
                  <h3 onClick={handleBackFormTwo} className="signup-back-arrow">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back
                  </h3>
                  <div className="signup-formgroup">
                    <label>PASSWORD</label>
                    <div>
                      <input type={passwordType} name="password" onChange={handleFormChange} value={password} />
                      {passwordType === "password" ? (
                        <FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} onClick={togglePassword} />
                      )}
                    </div>
                    {firstFormValidator.message("password", formDetails.password, "required|password")}
                  </div>
                  <div className="signup-formgroup">
                    <label>REFERRAL</label>
                    <input type="text" name="referred_by" onChange={handleVerifyReferral} value={referred_by} />
                    <p className="success-tag">{isLoadingReferral ? "..." : null}</p>
                    {!referralStatus ? <p className="error-tag"> Invalid Referral Code</p> : null}
                    {referralName ? (<p className="success-tag"><span>Referred By : </span>{ referralName }</p>) : null}
                  </div>
                  <button className="signup-button" onClick={handleSignUp}>{ isLoading ? "..." : "CREATE ACCOUNT" }</button>
                </form>
              ) : formState === 3 ? (

                <form className="signup-form">
                  <h5 className="new-signup-title">Enter code sent to</h5>
                  <div className="new-signup-subtitle"> {!editPhone ? phone_number :
                    <div className="signup-formgroup">
                      <input type="tel" name="phone_number" value={phone_number}
                        onChange={handleFormChange} maxLength={12}
                      />
                      <button className="edit-phone-btn" onClick={generateOTP}>
                        {isLoadingRegenerate ? "..." : "SAVE"}
                      </button>
                    </div>
                  } {!editPhone ?
                    (<FontAwesomeIcon icon={faPenToSquare} onClick={toggleEditPhone} />) :
                    null }
                  </div>

                  {!editPhone ? (
                    <>
                      <div className="signup-formgroup">
                        <label>CODE</label>
                        <input type="text" onChange={handleOTPChange} name="otpInput" value={otpInput} />
                        {firstFormValidator.message("otp", otpInput, "required")}
                      </div>
                      <button className="signup-button" onClick={validateOTP}>{isLoadingValidate ? "..." : "CONTINUE"}
                      </button>
                      <div className="otp-subfooter">
                        {isLoadingRegenerate ? (
                          <div>Generating . . .</div>
                        ) : (
                          <div onClick={generateOTP}>Resend Code</div>
                        )}

                        {isCallInitiated ? (
                          <div>Processing . . . </div>
                        ) :
                          <div onClick={dictateOTP}><FontAwesomeIcon icon={faPhone} /> Call me instead
                          </div>
                        }
                      </div>
                    </>
                  ) : null}

                </form>
              ) : null}
            </div>
          </div>

        </div>
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
};

export default SignUp;
