// eslint-disable-next-line no-unused-vars
import React from "react";
import Banner from "./components/marketing/Banner";
import How from "./components/home/HowitWorks";
import Footer from "./components/home/Footer";

export default function NewHome() {
  return (
    <>
      <Banner />
      <How />
      <Footer />
    </>
  );
}
