// eslint-disable-next-line no-unused-vars
import React from "react";
// import RatingsStar from "../../../assets/img/ratings.png";
import Appstore from "../../../assets/img/newappstore.png";
import GooglePlay from "../../../assets/img/greengoogleplay.png";
import { useNavigate } from "react-router-dom";
import Proptypes from "prop-types";

const ReviewCard = ({ title, subTitle }) => (
  <div className="review-card">
    <h4>{title}</h4>
    <p>{subTitle}</p>
    {/* <div className="card-review-footer">
      <div className="ratings-container">
        <h2>4/5</h2>
        <a href=" https://play.google.com/store/apps/details?id=ng.monthly.mobile" target="_blank">
          <img src={RatingsStar} alt="monthly-ratings" />
        </a>
      </div>
      <div>
        <a href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024" target="_blank" rel="noreferrer">
          <img src={downloadicon} alt="monthly-download" />
        </a>
      </div>
    </div> */}
  </div>
);

export default function Review() {
  const navigate = useNavigate();
  const handleSignup = () => navigate("/agents/signup");

  return (
    <div className="agent-review-container">
      <div className="page-container">
        <h2 className="agent-review-heading">
            100% of Monthly’s funded customers would recommend Monthly to family or friends
        </h2>
        <div className="review-card-container">
          <ReviewCard downloadicon={Appstore} title="Timely and Convenient"
            subTitle="A very timely and convenient solution. My experience with your team from day one was top-notch."
          />
          <ReviewCard downloadicon={GooglePlay} title="Fast and Smooth"
            subTitle="Very reliable, fast disbursement.
          True to their words about no hidden charges! Application and details are simple to understand."
          />
        </div>
        <button className="agent-sign-up-button" onClick={handleSignup}>Get Started</button>
      </div>
    </div>
  );
}

Review.proptypes = {
  downloadicon: Proptypes.string,
  title: Proptypes.string,
  subTitle: Proptypes.string
};
