// eslint-disable-next-line no-unused-vars
import React from "react";
import Header from "./components/how-it-works/Header";
import Content from "./components/how-it-works/Content";
import Tiles from "./components/how-it-works/Tiles";

export default () => {
  return (
    <>
      <Header />
      <Content />
      <Tiles />
    </>
  );
};
